import React, {useEffect, useState} from "react";

import "./style.css";
import {products} from "../../data";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {Minus, Plus} from "../../assets/svgIcons/svgIcons";
import PriceCard from "../../components/productDetailsPage/PriceCard/PriceCard";
import AddToCartButton from "./../../components/productDetailsPage/addToCartButton/AddToCartButton";
import Modal from "../../components/Modal/Modal";

import Lottie from "lottie-react";
import cartLottie from "../../assets/lotties/Animation - 1715438097771.json"; // Path to your animation file
import axios from "axios";
import {BASEURL} from "../../API/BaseUrl";
import toast from "react-hot-toast";
import UseGeneral from "../../hooks/useGeneral";
import {getRender} from "../../redux/rerednerSlice";
import {useDispatch} from "react-redux";
import useUserId from "../../hooks/useUserId";

const ProductDetailsPage = () => {
  const {catId, prodId} = useParams();
  const [counter, setCounter] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();

  const [prodData, setProdData] = useState("");
  const [loading, setLoading] = useState(true);
  const [isInCart, setIsInCart] = useState(false);

  const {language} = UseGeneral();

  const [prodPrices, setProdPrices] = useState([]);
  const dispatch = useDispatch();

  const getProdData = async () => {
    const dataSet = {
      category_id: +catId,
      language_type: language == "ar" ? "arabic" : "english",
    };

    await axios
      .post(`${BASEURL}products/select_home_data.php`, JSON.stringify(dataSet))
      .then((res) => {
        if (res.data.status == "success") {
          const categoryProducts = res?.data?.message?.products;
          const thisProductData = categoryProducts.find(
            (prod) => prod?.product_code == prodId
          );
          
          setProdData(thisProductData);

          const storedStores = JSON.parse(localStorage.getItem("ksbStores"));

          // these lines to get product prices in array of objects
          const prodDataPrices = JSON.parse(
            thisProductData.prices?.replace(/'/g, '"')
          )
            .filter((price) => {
              const isExist = storedStores?.find(
                (store) =>
                  store[
                    language == "ar" ? "market_name_ar" : "market_name_en"
                  ] == price[0]
              );
              if (isExist) {
                return true;
              }
            })
            .map((item) => {
              return {
                name: item[0],
                value: item[1],
              };
            })
            .map((item) => {
              let itemData = storedStores.find(
                (store) =>
                  store[
                    language == "ar" ? "market_name_ar" : "market_name_en"
                  ] == item.name
              );
              return {
                ...itemData,
                price: item.value,
              };
            });
          setProdPrices(prodDataPrices);
        } else {
          toast.error("هناك مشكلة ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };
  const {userId} = useUserId();

  const [searchParam, setSearchParam] = useSearchParams();

  const getProductCartData = async () => {
    setLoading(true);
    const dataSet = {
      user_id: userId,
      language_type: language == "ar" ? "arabic" : "english",
    };
    await axios
      .post(
        `${BASEURL}products/select_user_favorite.php`,
        JSON.stringify(dataSet)
      )
      .then((res) => {
        if (res.data.status == "success") {
          setCounter((prev) => {
            return (
              res?.data.message.find((item) => item?.product_code == prodId)
                ?.count || 0
            );
          });

          setIsInCart(
            Boolean(
              res?.data.message.find((item) => item?.product_code == prodId)
            )
          );

          console.log(
            res?.data.message.find((item) => item?.product_code == prodId)
          );
        } else {
          toast.error("هناك مشكلة ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (userId) {
      getProductCartData();
      getProdData();
    }
  }, [userId]);

  const handlePlusCounter = () => {
    // setCounter(+counter + 1);
    changeCountFromCart("addOne")
    setCounter(prev => +prev + 1)

  };

  const hendleMinusCounter = () => {


    changeCountFromCart("removeOne")
    setCounter(prev => +prev - 1)


    // if (+counter <= 0) {
    //   return;
    // }
    // setCounter(+counter - 1);
  };

  const handleRemoveAll = () =>{
    changeCountFromCart("removeAll");
    setCounter(prev => 0)


  }

  const changeCountFromCart = async (action) => {
    // if (!isInCart && counter == 0) {
    //   toast.error("قم بإضافة المنتج");
    //   return;
    // }

    // setLoading(true);
    const dataSet = {
      product_code: prodData?.product_code,
      user_id: userId,
      // count: action == "add" ? !isInCart && counter == 0 ? 1 :  !isInCart &&   +counter : 0,
      count: action == "addOne" ? +counter + 1 : action =="removeOne" ? +counter - 1 : 0,
      // every time send the new count if up or low
      // if you send zero the fav will be removed auto
    };

    await axios
      .post(`${BASEURL}products/add_edit_to_fav.php`, JSON.stringify(dataSet))
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          // getProductCartData();
          if (action == "addOne") {
            toast.success(language == "ar"? "تمت الإضافة الى السلة بنجاح" : "Added successfully");
          }else{
            toast.success(language == "ar"? "تمت  الازالة من السلة بنجاح" : "Deleted successfully");

          };
          
          dispatch(getRender());
        } else {
          toast.error(
            language == "ar" ? "هناك مشكلة ما" : "There is a problem!"
          );
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        // setLoading(false);
      });
  };

  if (loading) {
    return (
      <div className='d-flex align-items-center justify-content-center m-5'>
        <div class='spinner-border text-primary text-center' role='status'>
          <span class='visually-hidden text-center'>Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className='product_details_container'>
      <div className='prod_image'>
        <img src={prodData?.caption} alt='' />
      </div>
      <div className='prod_data'>
        <div className='prod_name_price'>
          <div className='prod_name'>{prodData?.name}</div>
          <div className='prod_price'>
            <div className='prod_description'>{prodData?.name}</div>
            <div className='price'>
              {Math.min(...prodPrices.map((item) => item.price))}{" "}
              {language == "ar" ? "ر.س" : "SAR"}
            </div>
          </div>
        </div>

        {/* <div className='counter_container'>
          <div className={`counter_icon right ${counter <= 1 ? "not_active" : ""}`}
            onClick={hendleMinusCounter}>
            {Minus}
          </div>
          <div className='devider'></div>
          <div className='digit_counter h-100'>{counter}</div>
          <div className='devider'></div>
          <div className='counter_icon left' onClick={handlePlusCounter}>
            {Plus}
          </div>
        </div> */}
        {/* <hr /> */}

        <div className='prices_header'>
          {language == "ar" ? "أسعار المتاجر" : "Markets Prices"}
        </div>
        {
          prodData && (
            <>
              <div className='markets_prices'>
                {prodPrices.map((price, index) => {
                  return (
                    <div
                      className={`market_name_price ${
                        +Math.min(...prodPrices.map((price) => price.price)) ==
                        +price.price
                          ? "active"
                          : ""
                      }`}
                    >
                      <div className='market_name'>
                        {language == "ar"
                          ? price?.market_name_ar
                          : price.market_name_en}
                      </div>
                      <div className='market__price'>{price.price}</div>
                    </div>
                  );
                })}
              </div>
              {/* <table className='cart_page_table'>
            <thead className='table_header'>
              <tr>
                {prodPrices
                  .map((price, index) => {
                    return <th className='marketName'>{language == "ar" ? price?.market_name_ar : price.market_name_en}</th>;
                  })}
              </tr>
            </thead>

            <tbody>
              <tr>
                {prodPrices
                  .map((value, index) => {
                    return (
                      <th
                        className={`marketPrice ${+Math.min(
                          ...prodPrices.map(price => price.price)
                        ) == +value.price
                          ? "active"
                          : ""
                          }`}
                        key={index}
                      >
                        {value.price}
                      </th>
                    );
                  })}
              </tr>
            </tbody>
          </table> */}
            </>
          )

          // <div className='row m-0 p-0 '>
          //   {
          //     prodPrices
          //   ?.map((price, index) => {
          //     return (
          //       <div className='col-6 m-0 px-2 mb-4'>
          //         <PriceCard key={index} priceData={price} />
          //       </div>
          //     );
          //   })}

          // </div>
        }
      </div>

      <AddToCartButton
        counter={counter}
        onMinusClick={hendleMinusCounter}
        onPlusClick={handlePlusCounter}
        isExist={counter >=1}
        onClick={() => counter >=1 ? handleRemoveAll() : handlePlusCounter()}
      />

      {/* <div className="" style={{
        marginBottom: '100px'
      }}></div> */}

      <Modal
        animation={true}
        // headerIcon={"x"}
        showCloseBtn={false}
        // overlay
        // overlay={false}
        size='calc(100% - 80px)'
        // title="Modal Title"
        show={showModal}
        onClose={() => setShowModal(false)}
      >
        <div className='success_message_container'>
          <div style={{width: 150, height: 150}}>
            <Lottie animationData={cartLottie} />
          </div>

          <div className='success_message'>
            <div className='success_text'>
              {language == "ar"
                ? "تم إضافة المنتج بنجاح"
                : "You added product successfully"}
            </div>

            <div className='popup_btns'>
              <div className='close_btn' onClick={() => setShowModal(false)}>
                {language == "ar" ? "إغلاق" : "Cancel"}
              </div>
              <div className='list_btn' onClick={() => navigate("/cart")}>
                {language == "ar" ? "السلة" : "Cart"}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ProductDetailsPage;
