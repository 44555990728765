import React from "react";
import "./style.css";
import UseGeneral from "../../../hooks/useGeneral";

const AddToCartButton = ({
  isExist,
  onClick,
  counter,
  onMinusClick,
  onPlusClick,
}) => {
  const {language} = UseGeneral();

  return (
    <>
      <div className='add_to_cart_container'>
        <div className='counter'>
          <PlusIcon onClick={onPlusClick} />
          <div className='counter_number'>{counter}</div>
          {counter > 0 && <MinusIcon onClick={onMinusClick} />}
        </div>

        <button className='add_to_cart_btn' onClick={onClick}>
          <CartIcon />
          <span className='text'>
            {
              isExist ? 
              language == "ar" ? " حذف من السلة" : "Delete to cart"
              :
              language == "ar" ? "أضف إلى السلة" : "Add to cart"


            }
          </span>
        </button>
      </div>
    </>
  );
};
export default AddToCartButton;

const MinusIcon = (props) => (
  <svg
    viewBox='303.872 238.087 31.5 31.5'
    width={31.5}
    height={31.5}
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M 319.622 238.087 C 315.46 238.137 311.483 239.813 308.541 242.756 C 305.598 245.699 303.922 249.676 303.872 253.837 C 303.922 257.999 305.598 261.975 308.541 264.918 C 311.483 267.861 315.46 269.537 319.622 269.587 C 323.783 269.537 327.76 267.861 330.703 264.918 C 333.646 261.975 335.321 257.999 335.372 253.837 C 335.321 249.676 333.646 245.699 330.703 242.756 C 327.76 239.813 323.783 238.137 319.622 238.087 Z M 328.622 254.962 L 320.747 254.962 L 320.747 254.967 L 318.497 254.967 L 318.497 254.962 L 310.622 254.962 L 310.622 252.712 L 318.497 252.801 L 318.497 252.805 L 320.747 252.805 L 320.747 252.813 L 328.622 252.712 L 328.622 254.962 Z'
      fill='#F26E38'
    />
  </svg>
);

const PlusIcon = (props) => (
  <svg
    width={36}
    height={36}
    viewBox='0 0 36 36'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M18 2.25C13.8385 2.30049 9.86172 3.97608 6.9189 6.9189C3.97608 9.86172 2.30049 13.8385 2.25 18C2.30049 22.1615 3.97608 26.1383 6.9189 29.0811C9.86172 32.0239 13.8385 33.6995 18 33.75C22.1615 33.6995 26.1383 32.0239 29.0811 29.0811C32.0239 26.1383 33.6995 22.1615 33.75 18C33.6995 13.8385 32.0239 9.86172 29.0811 6.9189C26.1383 3.97608 22.1615 2.30049 18 2.25ZM27 19.125H19.125V27H16.875V19.125H9V16.875H16.875V9H19.125V16.875H27V19.125Z'
      fill='#F26E38'
    />
  </svg>
);

const CartIcon = (props) => (
  <svg
    width={24}
    height={24}
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M8.25 20.25C8.66421 20.25 9 19.9142 9 19.5C9 19.0858 8.66421 18.75 8.25 18.75C7.83579 18.75 7.5 19.0858 7.5 19.5C7.5 19.9142 7.83579 20.25 8.25 20.25Z'
      stroke='white'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M18.75 20.25C19.1642 20.25 19.5 19.9142 19.5 19.5C19.5 19.0858 19.1642 18.75 18.75 18.75C18.3358 18.75 18 19.0858 18 19.5C18 19.9142 18.3358 20.25 18.75 20.25Z'
      stroke='white'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2.25 3.75H5.25L7.5 16.5H19.5'
      stroke='white'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.5 13.5H19.1925C19.2792 13.5001 19.3633 13.4701 19.4304 13.4151C19.4975 13.3601 19.5434 13.2836 19.5605 13.1986L20.9105 6.44859C20.9214 6.39417 20.92 6.338 20.9066 6.28414C20.8931 6.23029 20.8679 6.18009 20.8327 6.13717C20.7975 6.09426 20.7532 6.05969 20.703 6.03597C20.6528 6.01225 20.598 5.99996 20.5425 6H6'
      stroke='white'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
