import {useEffect, useState} from "react";
import Switch from "../../components/Switch/Switch";
import "./style.css";
import axios from "axios";
import {BASEURL} from "../../API/BaseUrl";
import UseGeneral from "../../hooks/useGeneral";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from 'react-redux';
import rerednerSlice, { getRender } from "../../redux/rerednerSlice";

export default function Stores() {


  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(false);
  const {language} = UseGeneral();
  const [storedStores, setStoredStores] = useState([]);

  const dispatch = useDispatch()

  const getStores = async () => {
    setLoading(true);
    const dataset = {
      city_id: JSON.parse(localStorage.getItem("ksbCity"))?.city_id,
    };

    await axios
      .post(`${BASEURL}products/select_markts.php`, JSON.stringify(dataset))
      .then((res) => {
        if (res.data.status == "success") {
          setStores(res?.data?.message[0].markts);
        } else {
          toast.error("هناك مشكلة ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };

  const getStoredStores = () =>{
      const sotredData = JSON.parse(localStorage.getItem("ksbStores")) || []
      setSelectedMarkets(sotredData)
  }


  const [selectedMarkets , setSelectedMarkets] = useState([])
  const rerender = useSelector(state => state.rerender.rerender)
  
  const onSelectMarket = (e , market)=> {

    const state = e.target.checked
     if(state){
        setSelectedMarkets([...selectedMarkets , market])
        onSaveStores([...selectedMarkets , market])
      }else{
       setSelectedMarkets(selectedMarkets.filter(m => m.market_id != market.market_id ));
       onSaveStores(selectedMarkets.filter(m => m.market_id != market.market_id ))
     }
     
  }


  const onSaveStores = (data)=>{  
    if(data.length == 0){
      toast.error(language == "ar" ? "قم باختيار متجر واحد على الاقل" :"Select at least one store")
      return
    }
    localStorage.setItem("ksbStores" , JSON.stringify(data))
    toast.success("تم الحفظ بنجاح")    

   }
  

  useEffect(() => {
    getStoredStores()
    getStores();
  }, [rerender]);

  return (
    !loading ?
    <div className={"storesContainer"}>
      {stores && stores.length >= 1 && Array.isArray(stores) ? (
        <>
          {stores.map((store, index) => {
            return (
              <div>
                <div className="market_image">
                  <img src="https://placehold.co/900" alt="" />
                </div>
                <div className="market_contnets">

                <p>
                  {language == "ar"
                    ? store.market_name_ar
                    : store.market_name_en}
                </p>
                <Switch checked={selectedMarkets.find(item => item.market_id == store.market_id)} onChange={(e)=> onSelectMarket(e , store)} id={index} />
                    </div>
              </div>
            );
          })}

          {/* <div className='bottom_sanve_btn'>
            <button
              onClick={()=>onSaveStores()}
              className='btn text-light'
              style={{backgroundColor: "#540965"}}
            >
              {language == "ar" ? "حفظ" : "save"}
            </button>
          </div> */}
        </>
      ) : (
        <div className=''>{

          language == "ar" ? "لا يوجد بيانات":"There is no data"

        }</div>
      )}
    </div>

    :
    
    <div className='d-flex align-items-center justify-content-center w-100'>
    <div class='spinner-border text-primary text-center' role='status'>
      <span class='visually-hidden text-center'>Loading...</span>
    </div>
  </div>
  );
}
