import React, {useRef, useState} from "react";
import "./style.css";
import {useNavigate} from "react-router-dom";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import "./style.css";
import {menuIcon} from "../../../assets/svgIcons/svgIcons";
import {Link} from "react-scroll";
import UseGeneral from "../../../hooks/useGeneral";

const SupCatsSlider = ({data}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef(null);

  const {language} = UseGeneral()

  const handleSlideChange = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      setActiveIndex(swiperRef.current.swiper.activeIndex);
    }
  };

  const handleClickOnSubCat = (id, position) => {
    setActiveIndex(position);
  };

  const goToSlide = (index) => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index + 1);
    }
  };

  const handleSetActive = (sectionIndex) => {
    setActiveIndex(sectionIndex);
    goToSlide(sectionIndex);
  };

  return (
    <div className='sub_cat_slider'>
      <span className='sub_cat_slider_icon'>{menuIcon}</span>
      <>
        <Swiper
          onSlideChange={handleSlideChange}
          // direction="rtl"
          spaceBetween={15}
          // slidesPerGroupSkip={4}
          slidesPerView={"auto"}
          className='mySwiper w-100 '
        >
          {data && data.length >= 1 && Array.isArray(data) ? (
            data?.map((item, index) => {
              return (
                <SwiperSlide key={index} style={{width: "fit-content"}}>
                  <Link
                    style={{textDecoration: "none"}}
                    to={String(item?.sub_category_name)}
                    smooth={true}
                    duration={100}
                    spy={true}
                    offset={-180} // Adjust this offset to leave space between the top and the scrolled-to element
                    onSetActive={() => handleSetActive(String(index))}
                  >
                    <div
                      onClick={() => handleClickOnSubCat(item.id, index)}
                      className={`sub_cat_text ${
                        activeIndex == index ? "active" : ""
                      }`}
                    >
                      {item.sub_category_name}
                    </div>
                  </Link>
                  {activeIndex == index && (
                    <div className='sub_cat_border_bottom'></div>
                  )}
                </SwiperSlide>
              );
            })
          ) : (
            <div className='text-dark fs-6'>{language == "ar" ? "لا يوجد فئات فرعية":"There are no subcategories"}</div>
          )}
        </Swiper>
      </>
    </div>
  );
};

export default SupCatsSlider;
