import { BiLogoTelegram } from "react-icons/bi";
import { BsStar, BsStars, BsTwitter } from "react-icons/bs";
import UseGeneral from "../../hooks/useGeneral";
import "./style.css";

import image  from "../../assets/images/contactUs.png"
import { EmailIcon , InstagramIcon, TiktokIcon, WhatsappIcon, XIcon } from "./Icons";

export default function ContactUs() {

  const {language} = UseGeneral()


  return (
    <div className={"about_page"}>
      <div className="about_illustration">
        <img src={image} alt="" />
      </div>
      
      <h3>نسعد باستقبال جميع اقتراحاتكم وملاحظاتكم</h3>

      <div className="contact_icons">
        {

          [
            {
            icon:<EmailIcon />,
            link:""
          },
          {
            icon:<WhatsappIcon />,
            link:""
          },
          {
            icon:<XIcon />,
            link:""
          },
          {
            icon:<TiktokIcon />,
            link:""
          },
          {
            icon:<InstagramIcon />,
            link:""
          },
        ].map((icon , index)=>{
          return(
            <div className="" key={index}>
              {icon.icon}
            </div>
          )
        })
      }
      </div>


    </div>
  );
}





