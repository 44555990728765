import { useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import AppRoutes from "./Routes/Routes";
import DefaultLayout from "./layouts/defaultLayout";
import toast, { Toaster } from "react-hot-toast";
import { useEffect, useState } from "react";
import SplashScreen from "./components/SplashScreen/SplashScreen";
import UseGeneral from "./hooks/useGeneral";
import UseSelectLocation from "./hooks/UseSelectLocation";

function App() {
  const location = useLocation();
  const navigate = useNavigate();


  const { location: userLocation } = UseSelectLocation();




  useEffect(() => {

    // Create a new URL object from the current window location
    const newUrl = new URL(window.location.href);

    // Extract the query string from the current URL
    const paramsString = newUrl.search; // Use '.search' to get the query string part
    const searchParams = new URLSearchParams(paramsString);

    // Retrieve 'user_id' from the query parameters
    const user_id_uri = searchParams.get("user_id");
    let newId = localStorage.getItem("user_id_uri");
    if ((!newId || newId == "null" || newId == null || newId == "undefined") || newId != user_id_uri) {
      localStorage.setItem("user_id_uri", user_id_uri);
      newId = localStorage.getItem("user_id_uri");
      console.log("user_id_uri", user_id_uri);
    }
    setTimeout(() => {
      // Retrieve the stored user ID from localStorage


      // Set the 'user_id' query parameter in the URL
      newUrl.searchParams.set('user_id', newId);

      // Update the URL without reloading the page if the history API is available
      if (window.history) {
        window.history.replaceState(null, '', newUrl.toString());
      }
    }, 300);




    // Example of a side effect with a timeout
    setTimeout(() => {
      // Your side effect code here
      console.log('Side effect triggered after URL update');
    }, 100);
  }, [location?.pathname]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading period
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // Adjust the time as needed

    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    // localStorage.clear()
  }, []);



  useEffect(() => {
    console.log(userLocation)
  }, [userLocation])

  return (
    <div className="App">
      {
        loading ? <SplashScreen /> :
          <DefaultLayout>
            {<AppRoutes />}
            <Toaster containerClassName="toastCon" />
          </DefaultLayout>
      }
    </div>
  );
}

export default App;
