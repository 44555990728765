import React, {useState, useEffect} from "react";
import CategoryCard from "../../components/homeComponents/CategoryCard";
import "./style.css";
import {products, categories} from "./../../data";
import MainCategories from "../../components/homeComponents/MainCategories/MainCategories";
import Categories from "../../components/homeComponents/Categories/Categories";

const HomePage = () => {
  const [selectedCat, setSelectedCat] = useState("");

  return (
    <div className='homePage_container'>
      <div className='cats_container'>
          <MainCategories />
          <Categories />
      </div>
    </div>
  );
};

export default HomePage;
