import React from "react";
import "./style.css";
import NewProductCard from "../../NewProductCard/NewProductCard";

const SupCatProductsSection = ({id , data}) => {
  return (
    <div className='sup_cat_prods_section' id={id || ""} >
      <div className='seciton_title'>{data.supCategory}</div>

      <div className='section_products' 
      >
        {
        data?.products && data?.products.length >= 1 && Array.isArray(data.products)?
        data.products
          .map((item, index) => {
            return <NewProductCard  data ={item}/>;
          })
        :
        ""
        }
      </div>
    </div>
  );
};

export default SupCatProductsSection;
