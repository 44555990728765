import CartPage from "../pages/CartPage/CartPage";
import ProductDetailsPage from "../pages/ProductDetailsPage/ProductDetailsPage";
import HomePage from "./../pages/HomePage/HomePage";
import SubCatProducts from "./../pages/subcategoryProducts/SubCatProducts";
import Settings from "./../pages/Settings/Settings";
import Stores from "./../pages/Stores/Stores";
import PrivacyPolicy from "./../pages/PrivacyPolicy/PrivacyPolicy";
import ContactUs from "./../pages/ContactUs/ContactUs";
import PriceAlerts from "./../pages/PriceAlerts/PriceAlerts";
import ChooseCountry from "./../pages/ChooseCountry/ChooseCountry";
import ChooseLanguage from "./../pages/ChooseLanguage/ChooseLanguage";
import CategoriesProds from "../pages/CategoriesProds/CategoriesProds";
import ProductSaved from "../components/NewProductCard/ProductsSaved";
import ThemesPage from "../pages/ThemesPage/ThemesPage";
import OffersPage from "../pages/OffersPage/OffersPage";
import OfferProductsPage from "../pages/OfferProductsPage.jsx/OfferProductsPage";

export const routes = [
  {
    path: "/",
    component: <HomePage />,
  },
  {
    path: "cats_prods/:id",
    component: <CategoriesProds />,
  },
  {
    path: "/ProductSaved",
    component: <ProductSaved />,
  },
  {
    path: "/subcatProducts/:subCatId",
    component: <SubCatProducts />,
  },
  {
    path: "/productDetails/:catId/:prodId",
    component: <ProductDetailsPage />,
  },
  {
    path: "/cart",
    component: <CartPage />,
  },
  {
    path: "/settings",
    component: <Settings />,
  },
  {
    path: "/settings/store",
    component: <Stores />,
  },
  {
    path: "/settings/privacyPolicy",
    component: <PrivacyPolicy />,
  },
  {
    path: "/settings/contactUs",
    component: <ContactUs />,
  },
  {
    path: "/settings/priceAlerts",
    component: <PriceAlerts />,
  },
  {
    path: "/settings/chooseLanguage",
    component: <ChooseLanguage />,
  },
  {
    path: "/settings/chooseCountries",
    component: <ChooseCountry />,
  },
  {
    path: "/settings/themes",
    component: <ThemesPage />,
  },
  {
    path: "/offers",
    component: <OffersPage />,
  },
  {
    path: "/offers_products/:id",
    component: <OfferProductsPage />,
  },
];
