import useSelection from "antd/es/table/hooks/useSelection";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import "./style.css";
import { useEffect, useState } from "react";
import ChooseCountry from "../../pages/ChooseCountry/ChooseCountry";
import UseGeneral from "../../hooks/useGeneral";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import StartStores from "../../pages/StartChooseMarket/StartChooseMaket";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import BottomDrawer from "../../components/BottomDrawer/BottomDrawer";

import panda from "../../assets/images/panda.png";
import othaim from "../../assets/images/otheim.jpg";
import danube from "../../assets/images/danube.png";
import carfour from "../../assets/images/carrefour.png";
import tamemey from "../../assets/images/tameme.png";
import PrintingCart from "../../components/PrintingCart/PrintingCart";
import { setItemFilter } from "../../redux/cartSlice";

const DefaultLayout = ({ classessName, children }) => {
  const [ksbCity, setKsbCity] = useState("");
  const [ksbStores, setKsbStores] = useState([]);
  const filterItem = useSelector((state) => state?.cart?.filterItem);
  const { language } = UseGeneral();

  const getSelectedCity = (action) => {
    const selectedCity = JSON.parse(localStorage.getItem("ksbCity"));
    const storedStores = JSON.parse(localStorage.getItem("ksbStores"));
    setKsbCity(selectedCity);
    setKsbStores(storedStores);
  };

  const rerender = useSelector((state) => state.rerender.rerender);

  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const openDrawer = () => setDrawerOpen(true);
  const closeDrawer = () => setDrawerOpen(false);

  useEffect(() => {
    getSelectedCity();
    // localStorage.clear();
  }, [rerender]);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const services = [
    {
      title_ar: "موقع المتجر على الخريطة",
      title_en: "Store location on map",
      icon: servicesIcons.location,
      stores: [
        {
          store: panda,
          onClick: () => {
            window.open(
              "https://www.google.com/maps/search/%D8%A8%D9%86%D8%AF%D9%87+%D8%A7%D9%84%D8%B3%D8%B9%D9%88%D8%AF%D9%8A%D8%A9%E2%80%AD/@24.6765776,46.8635588,11z?hl=ar&entry=ttu&g_ep=EgoyMDI0MDgyOC4wIKXMDSoASAFQAw%3D%3D",
              "_blank"
            );
          },
        },
        {
          store: othaim,
          onClick: () => {
            window.open(
              "https://www.google.com/maps/search/%D8%A7%D9%84%D8%B9%D8%AB%D9%8A%D9%85+%D8%A7%D9%84%D8%B3%D8%B9%D9%88%D8%AF%D9%8A%D8%A9%E2%80%AD/@24.6767575,46.8635579,11z/data=!3m1!4b1?hl=ar&entry=ttu&g_ep=EgoyMDI0MDgyOC4wIKXMDSoASAFQAw%3D%3D",
              "_blank"
            );
          },
        },
        {
          store: danube,
          onClick: () => {
            window.open(
              "https://www.google.com/maps/search/%D8%A7%D9%84%D8%AF%D8%A7%D9%86%D9%88%D8%A8+%D8%A7%D9%84%D8%B3%D8%B9%D9%88%D8%AF%D9%8A%D8%A9%E2%80%AD/@24.6788466,46.8621219,11z/data=!3m1!4b1?hl=ar&entry=ttu&g_ep=EgoyMDI0MDgyOC4wIKXMDSoASAFQAw%3D%3D",
              "_blank"
            );
          },
        },
        {
          store: carfour,
          onClick: () => {
            window.open(
              "https://www.google.com/maps/search/%D9%83%D8%A7%D8%B1%D9%81%D9%88%D8%B1+%D8%A7%D9%84%D8%B3%D8%B9%D9%88%D8%AF%D9%8A%D8%A9%E2%80%AD/@24.6886776,46.8074493,11z/data=!3m1!4b1?hl=ar&entry=ttu&g_ep=EgoyMDI0MDgyOC4wIKXMDSoASAFQAw%3D%3D",
              "_blank"
            );
          },
        },
      ],
    },
    {
      title_ar: "طباعة السلة",
      title_en: "Print Cart",
      icon: servicesIcons.printer,
      stores: [
        {
          store: panda,
          name: language == "ar" ? "هايبر بنده" : "Hyper Panda",
          onClick: () => {
            return filterItem == "هايبر بنده" || filterItem == "Hyper Panda"
              ? dispatch(setItemFilter(language == "ar" ? "" : ""))
              : dispatch(
                  setItemFilter(language == "ar" ? "هايبر بنده" : "Hyper Panda")
                );
          },
        },
        {
          store: othaim,
          name: language == "ar" ? "العثيم" : "Alothaim",
          onClick: () => {
            return filterItem == "العثيم" || filterItem == "Alothaim"
              ? dispatch(setItemFilter(""))
              : dispatch(
                  setItemFilter(language == "ar" ? "العثيم" : "Alothaim")
                );
          },
        },
        {
          store: danube,
          name: language == "ar" ? "الدانوب" : "Danube",
          onClick: () => {
            return filterItem == "الدانوب" || filterItem == "Danube"
              ? dispatch(setItemFilter(language == "ar" ? "" : ""))
              : dispatch(
                  setItemFilter(language == "ar" ? "الدانوب" : "Danube")
                );
          },
        },
        {
          store: carfour,
          name: language == "ar" ? "كارفور" : "Carrefour",
          onClick: () => {
            return filterItem == "كارفور" || filterItem == "Carrefour"
              ? dispatch(setItemFilter(language == "ar" ? "" : ""))
              : dispatch(
                  setItemFilter(language == "ar" ? "كارفور" : "Carrefour")
                );
          },
        },
      ],
    },
    {
      title_ar: "مشاركة السلة",
      title_en: "Share Cart",
      icon: servicesIcons.shareIcon,
      stores: [
        {
          store: panda,
          onClick: () => {
            // your code here 😊
          },
        },
        {
          store: othaim,
          onClick: () => {
            // your code here 😊
          },
        },
        {
          store: danube,
          onClick: () => {
            // your code here 😊
          },
        },
        {
          store: carfour,
          onClick: () => {
            // your code here 😊
          },
        },
      ],
    },
    {
      title_ar: "الشراء من التاجر",
      title_en: "Buy from merchant",
      icon: servicesIcons.buyIcon,
      stores: [
        {
          store: panda,
          onClick: () => {
            window.open("https://panda.sa/", "_blank");
          },
        },
        {
          store: othaim,
          onClick: () => {
            window.open("https://www.othaimmarkets.com/", "_blank");
          },
        },
        {
          store: danube,
          onClick: () => {
            window.open("https://danube.sa", "_blank");
          },
        },
        {
          store: carfour,
          onClick: () => {
            window.open("https://www.carrefourksa.com/mafsau/ar/", "_blank");
          },
        },
      ],
    },
  ];

  return (
    <div>
      <>
        <Header onOpenDrawer={openDrawer} />
        {children}
        <Footer />

        <BottomDrawer isOpen={isDrawerOpen} onClose={closeDrawer}>
          <h2 className="bottom_drawer_title">
            {language == "ar"
              ? "برجاء اختيار أحد الخدمات"
              : "Select one of the services"}
          </h2>
          <div className="servics_container">
            {services?.map((item, index) => {
              if (item.title_en == "Print Cart") {
                return (
                  <PrintingCart
                    printCart={"cart"}
                    triggerElement={
                      <>
                        <div key={index} className="service">
                          <div className="service_title">
                            {item.icon}
                            {language == "ar" ? item.title_ar : item.title_en}
                          </div>
                          <div className="services_stores">
                            {item?.stores &&
                              item?.stores?.map((store, index) => {
                                console.log(filterItem, store?.name)
                                return (
                                  <div
                                    onClick={() => store.onClick()}
                                    key={index}
                                    className={
                                      filterItem == store?.name
                                        ? "service_store_image active"
                                        : "service_store_image"
                                    }
                                  >
                                    <img src={store.store} alt="" />
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </>
                    }
                  />
                );
              }
              return (
                <div key={index} className="service">
                  <div className="service_title">
                    {item.icon}
                    {language == "ar" ? item.title_ar : item.title_en}
                  </div>
                  <div className="services_stores">
                    {item.stores &&
                      item?.stores?.map((store, index) => {
                        return (
                          <div
                            onClick={() => store.onClick()}
                            key={index}
                            className="service_store_image"
                          >
                            <img src={store.store} alt="" />
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            })}
          </div>
        </BottomDrawer>
      </>
    </div>
  );
};

export default DefaultLayout;

const servicesIcons = {
  location: (
    <svg
      width={14}
      height={20}
      viewBox="0 0 14 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 20C7 20 0 10.87 0 7C-1.36979e-08 6.08075 0.18106 5.1705 0.532843 4.32122C0.884626 3.47194 1.40024 2.70026 2.05025 2.05025C2.70026 1.40024 3.47194 0.884626 4.32122 0.532843C5.1705 0.18106 6.08075 0 7 0C7.91925 0 8.8295 0.18106 9.67878 0.532843C10.5281 0.884626 11.2997 1.40024 11.9497 2.05025C12.5998 2.70026 13.1154 3.47194 13.4672 4.32122C13.8189 5.1705 14 6.08075 14 7C14 10.87 7 20 7 20ZM7 9C7.53043 9 8.03914 8.78929 8.41421 8.41421C8.78929 8.03914 9 7.53043 9 7C9 6.46957 8.78929 5.96086 8.41421 5.58579C8.03914 5.21071 7.53043 5 7 5C6.46957 5 5.96086 5.21071 5.58579 5.58579C5.21071 5.96086 5 6.46957 5 7C5 7.53043 5.21071 8.03914 5.58579 8.41421C5.96086 8.78929 6.46957 9 7 9Z"
        fill="#1E1E1E"
      />
    </svg>
  ),
  printer: (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 3H6V7H18M19 12C18.7348 12 18.4804 11.8946 18.2929 11.7071C18.1054 11.5196 18 11.2652 18 11C18 10.7348 18.1054 10.4804 18.2929 10.2929C18.4804 10.1054 18.7348 10 19 10C19.2652 10 19.5196 10.1054 19.7071 10.2929C19.8946 10.4804 20 10.7348 20 11C20 11.2652 19.8946 11.5196 19.7071 11.7071C19.5196 11.8946 19.2652 12 19 12ZM16 19H8V14H16M19 8H5C4.20435 8 3.44129 8.31607 2.87868 8.87868C2.31607 9.44129 2 10.2044 2 11V17H6V21H18V17H22V11C22 10.2044 21.6839 9.44129 21.1213 8.87868C20.5587 8.31607 19.7956 8 19 8Z"
        fill="#1E1E1E"
      />
    </svg>
  ),
  shareIcon: (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 16.08C17.24 16.08 16.56 16.38 16.04 16.85L8.91 12.7C8.96 12.47 9 12.24 9 12C9 11.76 8.96 11.53 8.91 11.3L15.96 7.19C16.5 7.69 17.21 8 18 8C19.66 8 21 6.66 21 5C21 3.34 19.66 2 18 2C16.34 2 15 3.34 15 5C15 5.24 15.04 5.47 15.09 5.7L8.04 9.81C7.5 9.31 6.79 9 6 9C4.34 9 3 10.34 3 12C3 13.66 4.34 15 6 15C6.79 15 7.5 14.69 8.04 14.19L15.16 18.35C15.11 18.56 15.08 18.78 15.08 19C15.08 20.61 16.39 21.92 18 21.92C19.61 21.92 20.92 20.61 20.92 19C20.92 17.39 19.61 16.08 18 16.08Z"
        fill="#1E1E1E"
      />
    </svg>
  ),

  buyIcon: (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 6H18V4L6 4L6 20L18 20V18H20V21C20 21.2652 19.8946 21.5196 19.7071 21.7071C19.5196 21.8946 19.2652 22 19 22L5 22C4.73478 22 4.48043 21.8946 4.29289 21.7071C4.10536 21.5196 4 21.2652 4 21L4 3C4 2.73478 4.10536 2.48043 4.29289 2.29289C4.48043 2.10536 4.73478 2 5 2L19 2C19.2652 2 19.5196 2.10536 19.7071 2.29289C19.8946 2.48043 20 2.73478 20 3L20 6ZM18 13L11 13V11L18 11V8L23 12L18 16V13Z"
        fill="#1E1E1E"
      />
    </svg>
  ),
};
