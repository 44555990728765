import React from "react";

import {routes} from "./routesData";
import HomePage from "../pages/HomePage/HomePage";

import {Routes, Route} from "react-router-dom";


const AppRoutes = () => {
  return (
    <div>
      <Routes>
        {routes.map((item, index) => {
          return (
            <Route path={item.path} element={item.component} key={index} />
          );
        })}
        <Route path='*' element={<HomePage />} />
      </Routes>
    </div>
  );
};

export default AppRoutes;
