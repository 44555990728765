import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {BASEURL} from "../API/BaseUrl";
import {useDispatch} from "react-redux";
import toast from "react-hot-toast";
import axios from "axios";
import UseGeneral from "./useGeneral";
import {getRender} from "../redux/rerednerSlice";

const UseSelectLocation = () => {
  const {language, location, loadingLocation} = UseGeneral();
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  // done
  const getAllCities = async () => {
    setLoading(true);

    await axios
      .get(`${BASEURL}products/select_cities.php`)
      .then((res) => {
        if (res.data.status == "success") {
          setCities(res?.data?.message);
          console.log(res?.data?.message);
        } else {
          toast.error("هناك مشكلة ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    getAllCities();
  }, []);

  const chooseAllStores = async (city_id) => {
    setLoading(true);
    const dataset = {
      city_id,
    };

    await axios
      .post(`${BASEURL}products/select_markts.php`, JSON.stringify(dataset))
      .then((res) => {
        console.log(res);
        if (res.data.status == "success") {
          localStorage.setItem(
            "ksbStores",
            JSON.stringify(res?.data?.message[0].markts)
          );
          console.log(res?.data?.message[0].markts);
          dispatch(getRender());
        } else {
          toast.error("هناك مشكلة ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };

  const onSaveCity = () => {
    if (cities && cities.length) {
      const userCity = cities?.find((item) => item.name_ar == location?.city);
      const defaultCity = cities[0];

      if (userCity) {
        localStorage.setItem("ksbCity", JSON.stringify(userCity));
        chooseAllStores(userCity.city_id);
      } else {
        localStorage.setItem("ksbCity", JSON.stringify(defaultCity));
        chooseAllStores(defaultCity?.city_id);
      }
    }
  
    navigate("/");
  };

  useEffect(() => {
    if(!localStorage.getItem("ksbCity")){
    
    onSaveCity();
    }
  }, [cities, location]);

  return {
    location,
  };
};

export default UseSelectLocation;
