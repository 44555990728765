import React from 'react';
import "./style.css";
import logo from "../../assets/kssap.png";

const SplashScreen = () => {
  return (
    <div className="splash-screen">
      <h1>
        <img src={logo} alt="" />
      </h1>
      {/* You can include a logo or animation here */}
    </div>
  )
}

export default SplashScreen
