import {Link, useNavigate} from "react-router-dom";
import styles from "./Settings.module.css";
import {BsTranslate} from "react-icons/bs";
import { kspCart, kspLanguage, kspThemes, LanguageIcon , kspFileIcon, kspLocationIcon, kspCallIcon, kspSupport, kspWindow, kspShareIcon } from "../../assets/svgIcons/svgIcons";
import UseGeneral from "../../hooks/useGeneral";
import "./style.css"

export default function Settings() {
  const navigate = useNavigate();

  const {language} = UseGeneral()


  const kspSettings = [
    {
      title_ar:"الأسواق",
      title_en:"Markets",
      icon:kspCart,
      onClick:()=>navigate("/settings/store"),
    },
    {
      title_ar:"تحديد المدينة",
      title_en:"Select the city",
      icon:kspLocationIcon,
      onClick:()=>navigate("/settings/chooseCountries"),
    },
    {
      title_ar:"السمات",
      title_en:"Themes",
      icon:kspThemes,
      onClick:()=>navigate('/settings/themes'),
    },
    {
      title_ar:"الشروط والأحكام",
      title_en:"Terms and Conditions",
      icon:kspFileIcon,
      onClick:()=>navigate("/settings/terms"),
    },
    {
      title_ar:"تواصل معنا",
      title_en:"Contact Us",
      icon:kspCallIcon,
      onClick:()=>navigate("/settings/contactUs"),
    },
    {
      title_ar:"الدعم الفني",
      title_en:"Technical Support",
      icon:kspSupport,
      onClick:()=>navigate("/settings/support"),
    },
    {
      title_ar:"عن التطبيق",
      title_en:"About App ",
      icon:kspWindow,
      onClick:()=>navigate("/settings/about"),
    },
    {
      title_ar:"مشاركة التطبيق",
      title_en:"Share App ",
      icon:kspShareIcon,
      // onClick:()=>navigate("/settings/support"),
    },
    {
      title_ar:"العربية",
      title_en:"English",
      icon:kspLanguage,
      onClick:()=>navigate("/settings/chooseLanguage"),
    },
    
    {
      title_ar:"سياسة الخصوصية",
      title_en:"Share App ",
      icon:kspFileIcon,
      onClick:()=>navigate("/settings/privacyPolicy"),
    },
    
    
  ];
  
  return (
    <div className={styles.settingsContainer}>


      {
        kspSettings?.map((item , index)=>{
          return(
            <div onClick={item.onClick} className={styles.settingCard}>

              
              <div className={styles.settingsContent}>
                <h3>{language == "ar" ? item.title_ar: item.title_en}</h3>
              </div>
              <div>
              {item.icon}
              </div>
            </div>
            
          )
        })
      }

    </div>
  );
}
