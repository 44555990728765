import React, { useEffect, useState } from "react";

import prod from "../../assets/images/prod_1.png";
import "./style.css";
import { Plus } from "../../assets/svgIcons/svgIcons";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, minusFromCart } from "../../redux/cartSlice";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { BASEURL } from "../../API/BaseUrl";
import axios from "axios";
import toast from "react-hot-toast";
import UseGeneral from "../../hooks/useGeneral";
import { getRender } from "../../redux/rerednerSlice";
import useUserId from "../../hooks/useUserId";

const NewProductCard = ({ data }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [cartData, setCartData] = useState([]);
  const [itemCart, setItemCart] = useState(null);


  const { language } = UseGeneral()

  const [searchParam, setSearchParam] = useSearchParams()
  const getCartData = async () => {
    setLoading(true);
    try {
      const dataSet = {
        user_id: userId,
        language_type: language == "ar" ? "arabic" : "english"
      };
      await axios
        .post(
          `${BASEURL}products/select_user_favorite.php`,
          JSON.stringify(dataSet)
        )
        .then((res) => {
          if (res.data.status == "success") {
            setCartData(res?.data?.message);
            setItemCart(
              res?.data.message.find((item) => item.product_code == data.product_code)
            );
            const marketPrices = {};
            const allPrices = {};

            // Iterate over each item in the array
            res?.data?.message?.forEach(item => {
              // Parse the prices JSON string
              let prices = JSON.parse(item.prices.replace(/'/g, '"'));
              if (!allPrices[item.product_code]) {
                allPrices[item.product_code] = [];
              }

              prices.forEach(([marketName, priceStr]) => {
                const price = parseFloat(priceStr);
                allPrices[item.product_code].push({ marketName, price });

                // Ensure product_code is used to track the lowest price for each product
                if (!marketPrices[item.product_code] || price < marketPrices[item.product_code].price) {
                  marketPrices[item.product_code] = {
                    price: price,
                    marketName: marketName,
                    item: item
                  };
                }
              });
            });

            // Determine preferred markets dynamically based on lowest prices
            const marketSavings = {};

            // Find the lowest price from each market
            Object.values(marketPrices).forEach(({ price, marketName, item }) => {
              if (!marketSavings[marketName]) {
                marketSavings[marketName] = { totalPrice: 0, products: [] };
              }
              marketSavings[marketName].totalPrice += price;
              marketSavings[marketName].products.push({
                product_code: item.product_code,
                name: item.name,
                caption: item.caption,
                summary: item.summary,
                sub_category_name: item.sub_category_name,
                price
              });
            });

            // Determine markets with lowest prices for each product
            const preferredMarkets = Object.entries(marketSavings)
              .filter(([marketName, { totalPrice }]) => totalPrice > 0)
              .sort(([, a], [, b]) => a.totalPrice - b.totalPrice)
              .map(([marketName]) => marketName);

            // Calculate the total savings and the total amount from preferred markets
            let totalSavings = 0;
            let totalPreferredPrice = 0;

            // Find lowest prices from preferred markets
            Object.entries(allPrices).forEach(([productCode, prices]) => {
              const lowestPrice = marketPrices[productCode].price;
              const highestPrice = Math.max(...prices.map(({ price }) => price));

              // Calculate savings
              const savings = highestPrice - lowestPrice;
              totalSavings += savings;

              // Find the lowest price from preferred markets
              const preferredPrice = prices.find(p => preferredMarkets.includes(p.marketName));
              if (preferredPrice) {
                totalPreferredPrice += preferredPrice.price;
              }
            });

            // Calculate saved prices and convert the marketPrices object to an array
            const cheapestPricesArray = Object.values(marketPrices).map(({ price, marketName, item }) => {
              const productPrices = allPrices[item.product_code];

              const savedPrice = productPrices.reduce((maxSaved, { price: p }) => {
                return p > price ? Math.max(maxSaved, p - price) : maxSaved;
              }, 0);

              return {
                product: {
                  product_code: item.product_code,
                  name: item.name,
                  caption: item.caption,
                  summary: item.summary,
                  sub_category_name: item.sub_category_name
                },
                market: {
                  market_name: marketName
                },
                price: price,
                savedPrice: savedPrice
              };
            });

            const result = `
    بإمكانك توفير ${totalSavings.toFixed(2)} ريال عند اختيار البضائع الأرخص سعرا من ${preferredMarkets.join(' و ')} ليصبح المجموع ${totalPreferredPrice.toFixed(2)} ريال;
`

          } else {
            toast.error("هناك مشكلة ما");
          }
        })
        .catch((e) => console.log(e))
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
    }
  };


  const { userId } = useUserId();


  useEffect(() => {
    if (userId)
      getCartData();
  }, [userId]);


  const handleChangeCart = async (action) => {
    setLoading(true);

    const dataSet = {
      product_code: data?.product_code,
      user_id: userId,
      count:
        action == "add"
          ? itemCart
            ? +itemCart.count + 1
            : 1
          : +itemCart.count - 1, // every time send the new count if up or low
      // if you send zero the fav will be removed auto
    };

    // return
    await axios
      .post(`${BASEURL}products/add_edit_to_fav.php`, JSON.stringify(dataSet))
      .then((res) => {
        if (res.data.status == "success") {
            if(action == "add"){
              toast.success("تمت إلإضافة إلى السلة بنجاح");
            }else{
              toast.success("تم الحذف من السلة بنجاح");

            }
          getCartData();
          dispatch(getRender())
        } else {
          toast.error("هناك مشكلة ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };


  const { id } = useParams()

  return (
    <div
      className='new_prod_card'
      onClick={(e) => {
        e.stopPropagation();
        navigate(`/productDetails/${id}/${data?.product_code}`);
      }}
    >
      <div className='np_image'>
        <img src={data.caption} alt='' />

        <div
          className='add_to_cart_button'
          onClick={(e) => {
            e.stopPropagation();
            handleChangeCart("add");
          }}
        >
          {Plus}
        </div>
        
        {itemCart && itemCart?.count >= 1 && (
          <>
            <div
              className='remove_from_cart_button'
              onClick={(e) => {
                e.stopPropagation();
                handleChangeCart("minus");
              }}
            >
              -
            </div>
            <div className='cart_count_padge'>{itemCart?.count}</div>
          </>
        )}
      </div>

      <div className='np_prod_info'>
        <dvi className='np_prod_name'>{data.name}</dvi>
        <div className='np_prod_price'>
          {Math.min(
            ...JSON.parse((data?.prices).replace(/'/g, '"')).map(
              (item) => +item[1]
            )
          )}
          <span className='price_unit'> {language == "ar" ? "ريال" : "SAR"}</span>
        </div>
      </div>
    </div>
  );
};

export default NewProductCard;
