

import React, { useEffect, useState } from 'react'
import "./style.css"
import { products, subSubCats } from '../../data'
import ProductCard from '../../components/productCard/ProductCard'
import SubSubCatFilter from './../../components/subSubCatFilter/SubSubCatFilter';

const SubCatProducts = () => {
  const [selectedSubSubCat , setSelectedSubSubCat] = useState('all')
  const [allProducts , setAllProducts] = useState([])


  const getAllProductsData = () =>{
    setAllProducts(products)
  }
  
  const getFilteredData = () =>{
    const filteredData = products.filter(prod => prod?.subSubCat_id == selectedSubSubCat?.id)
    setAllProducts(filteredData)

  }

  useEffect(()=>{
    getAllProductsData()
  },[])


  useEffect(()=>{

      if(selectedSubSubCat == 'all'){
        getAllProductsData()
      }else{
        getFilteredData()

      }
  },[selectedSubSubCat])
  
  return (
    <div className='cateProds_container'>
      <div className='subsubCats'>
      <SubSubCatFilter subSubCatsData={subSubCats}  selectedSubSubCat={selectedSubSubCat} setSelectedSubSubCat={setSelectedSubSubCat} />
      </div>

        <div className='row'>
          {
            allProducts?.map(product => {
              return(
                <div className='col-6 my-2'>
                  <ProductCard prod={product} />
                </div>
              )
            })
          }



        </div>
    </div>
  )
}

export default SubCatProducts
