import React from 'react'
import apple from "../../assets/images/apple.jpg"
import './style.css'
import { useNavigate } from 'react-router-dom'


const ProductCard = ({prod}) => {
  const navigate = useNavigate()
  return (
    <div className='prod_card' onClick={() => navigate(`/productDetails/${prod.id}`) } >
        <div className='image'>
          <img src={prod.img} alt="" />
        </div>
        <div className='prod_data'>
            <div className='prod_name'>{prod.name}</div>
            <div className='price'>السعر : 6.00$ to 8.00$</div>
        </div>

        <div className='cart_btn'>أضف إلى السلة</div>
    </div>
  )
}

export default ProductCard 
