

import React from 'react'
import "./style.css"
import { useNavigate } from 'react-router-dom'


const OffersPage = () => {

  const markets = [
    {
      name:"العثيم",
      image:"https://s3-alpha-sig.figma.com/img/7947/c1ea/42153651bb81542bb0da28b5b40bf147?Expires=1725235200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=nX72oY849xnCSuEqEH2nqJNAHaYe5OC5N4eJSlOlPycC~s5fgiqs0tz4EEdT4JcMMcjhLrPDS5A~H0NBDrMqmBWRk3coxnC-nNUPIDK6~ugMOoh574W~DGeDrP2wIT4t~o6H-YPfDhVcXJS2g2EftJ4GoaO0w2IaAfIAXI~G094r41ghM8x8XOm73Aj9mVaFElwkad9uO8~8oALn9dYrgVGO9H7k7Jq7dPFZv2P74tqhi1fYKnlOLjDb4VGD2Tk5ydgfZ6WepJPyyXBE-KKKsbg8bM7D9tohjMr0DuFUXtt2R2LuzyvX74OfN2Yt8Lm~EAx2FI5ZmMXXcEUQWcZ-1A__",
    },
    {
      name:"هيبر بنده",
      image:"https://s3-alpha-sig.figma.com/img/3fd4/788a/cdadb6a35c3c2296595a38b0b5574e09?Expires=1725235200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Q5Qu8C3EPck132Nxlj8YlLkivz-MDpXApEzSN4ji7fgvOTLqLgYhlaKjxZ-jgD13qbt9bth24OqW0TiyVryJMp4KMhW049e8cV-j6KYrEjxT2AX1xmvUz9m83xFptFQS6fDp8h2aL5VhnBTBo-hPJZwYF5yaj5Tj-96hAQYuc~ykf4Gp4A~mexlSnm0YYo4ZeH3JFPavqbDvLgoyRAraS3qrC8ZfyJ~1SC2JafImjogPunnAszPEJGGUWik4duDJDsI5Cduzncm1dG~WpxCH-rXdKKHJhFYcXpYU2du1c9rBV1YbNtIu0uHAlyy~OrKSzgo2SENPg483YjocAoOj9Q__"
    },
    {
      name:"كارفور",
      image:"https://s3-alpha-sig.figma.com/img/5b71/d345/9d3fdc09fbf7146a6c8f0b76a42ee278?Expires=1725235200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ctt1Vz-iA8TNqv2XPisvt1UYzAAt~MSuVBi6gxFprzIMNp88ZtkwInrGEa5Tj4b8odHe5NW2KILU8~29KkGqNWEzf0llJl70XptGY1QGwi17B0zp66SXrgjc520vFYNjr1TaOtYTVuD7XKysrjpxdVc3Y6VKQBaE4Nhok2QHO6SGVNxSDJEtzalcMEiLeV69GvwIl7K7g2axyzmayHVpJp9gUiAUzjNlEKvvFrtePBNJ7mr53P4jtHLjwqYq4kFTioCXeZBAnIUwKPP6pcYqtXSMCpsM4jDaIm4JDvnwYZ5sG9YEUedapuCWxvFBxCQ3MZZkuhGWaoIhjj-z0p9AAw__"
    },
    {
      name:"التميمي",
      image:"https://s3-alpha-sig.figma.com/img/c04c/de82/4bd2ad298c404be09ad6109084fd6c77?Expires=1725235200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gALKzEt8QukJvUdilGZG9KAJxWWP8-ni2z0x3vDSBIAzCWu1U7VKn~iKVJGmMnHyNeGecCbT05qH4KvA1TFVyk0YGGtSGne9PrZ9mlHv1TXUBOhYmdXrqrOnRxilgfUv-rbbdGuvCVklQHZlu6IaSqm4eWQ2kWq-L2ZN1J6-L5R1yeMLUoYUVjwQ79SiLW2wi2UsPVaGH77OUR3SMrVNeAkbWlYl5GH9kPjL-CddwUDlWfJMUOxVUDzWSiAhA8rNcsXaAlNexjM0ltxCQBH7~2RG7Ei6JsaZj0xZ6pUrUDiRwB0XK91E8YSqePjKvhpUzM2W5ma6gmnSFThcTzHj8w__"
    },
    {
      name:"الدانوب",
      image:"https://s3-alpha-sig.figma.com/img/1575/284d/64c43272ead92c3f1bc6d4d1915b6d0e?Expires=1725235200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=lnQTZfXT~sigkBBnwoPDfDQZ9dvkE2ZAxUSmq0FmvK4YruL1ZnFZ5-vxfmeUa6uVDiD9UNDJgyMC6ojVinF0QXuOIxwklOTzXObaRVku-LPp2JtbKbmerIwI7pwQZtlxV5Hv0CBpxLL-Buktjk-ggeB9uEwPnwS-FfE5kBqxTbHCjXce9DhKqKRofWzepBNWdageTE0IuZDXzxIFjDuquBx9YBTGtDjse8fi70yYxMKUPY87x8nTQBRFUGEOvnuf-ggO1e4SdRJMjMGlXMdRiR5lgSFgh0hygWfv3MbicXzRQPM8Hybi4yFYXdpDMFPWcfChFXO9nszzoQl3BZDxfw__"
    },
  ]

  const navigate = useNavigate()

  return (
    <div className='offersPage'>
      <div className='offers_markets_container'>
        {
          markets.map((item, index)=>{
            return(
              <div className="offer_market_card" onClick={()=>navigate("/offers_products/1")}>
                  <img src={item.image} alt="" />
              </div>
            )
          })
        }
      </div>
      
    </div>
  )
}

export default OffersPage




