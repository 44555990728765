import React from 'react'
import './style.css'
import {kspCloseSearch, kspSearch, SearchRight } from '../../assets/svgIcons/svgIcons'

const SearchInput = ({
  placeholder,
  value,
  reference,
  containerStyles,
  onChange,
  onFocus,
  
  isFocust,
  inputStyles,
  onCloseClick,
  ...props
}) => {
  return (
    <div className="searchInput" style={{width:'100%'}} >
      <div className='searchIcon'>{kspSearch}</div>
      <input ref={reference} onFocus={onFocus? onFocus: null} value={value} style={inputStyles} {...props} type="text" onChange={onChange} placeholder={placeholder ||  "Search..."} />
      {
       isFocust &&
      <div className='searchIcon' onClick={onCloseClick}>{kspCloseSearch}</div>
      }
    </div>
  )
}

export default SearchInput
