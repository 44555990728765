import SwitchComponent from '../../components/Switch/Switch';
import styles from './PriceAlerts.module.css';
import { Switch } from 'antd';

export default function PriceAlerts() {
  return (
    <div className={styles.priceAlertsContainer}>
        <div>
            <p>Exclusive supermarket offers</p>
            <SwitchComponent isChecked={true}/>
        </div>

        <div>
            <p>Offers on products you may like</p>
            <SwitchComponent isChecked={false}/>
        </div>

        <div>
            <p>Price drop alerts for items in watchlist</p>
            <SwitchComponent isChecked={false}/>
        </div>
    </div>
  )
}
