import React from "react";
import "./style.css";
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import "./style.css";

import offer1 from "../../../assets/images/offer1.png";
import offer2 from "../../../assets/images/offer-2.png";
import TopOfferCard from "../Topoffers/TopOfferCard/TopOfferCard";
import OfferCard from "../OfferCard/OfferCard";

const OfferSwiperSection = ({title , onShowAll , data, children}) => {
  return (
    <div className='offer_swiper_section'>
      <div className="offer_swiper_section_title">
        <h4>{title}</h4>
        <h5 onClick={onShowAll}>عرض الكل</h5>
      </div>
      <Swiper
        // direction="rtl"
        spaceBetween={13.5}
        // slidesPerGroupSkip={4}
        slidesPerView={"auto"}
        className='mySwiper w-100 '
      >
        {data.map(
          (offer, index) => (
            <SwiperSlide key={index} style={{width: "fit-content"}}>
              {React.cloneElement(children, { data: offer })}
            </SwiperSlide>
          )
        )}
      </Swiper>
    </div>
  );
};

export default OfferSwiperSection;
