import React, {useState} from "react";
import "./style.css";
import img from "../../assets/images/themes.png";
import RadioButton from "../../components/RadioButton/RadioButton";

const ThemesPage = () => {
  const [selectedOption, setSelectedOption] = useState("light");

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className='themes_page'>
      <div className='themesImage'>
        <img src={img} alt='' />
      </div>

      <div className='custom-radio-group'>
        <RadioButton
          value='light'
          checked={selectedOption === "light"}
          onChange={handleChange}
          id={"light"}
          labelStyle={{fontSize: "16px", fontWeight: "600"}}
        >
          صباحى
        </RadioButton>
        <RadioButton
          id={"dark"}
          value='dark'
          checked={selectedOption === "dark"}
          onChange={handleChange}
          labelStyle={{fontSize: "16px", fontWeight: "600"}}
        >
          ليلى
        </RadioButton>
      </div>
    </div>
  );
};

export default ThemesPage;
