import React, { useEffect } from 'react'
import "./style.css"

const BottomDrawer = ({ isOpen, onClose, children  }) => {

  
  useEffect(()=>{
    if(isOpen){
      document.body.style.overflow  = "hidden"
    }else{
      document.body.style.overflow  = "auto"
    }

  },[isOpen])






  return (
    <>
      {isOpen && <div className="overlay" onClick={onClose}></div>}
      <div className={`bottom-drawer ${isOpen ? 'open' : ''}`}>
        <div className='drawer_content'>
        {children}
        </div>

      <div className='close-drawer' onClick={onClose}>

          <Close />

      </div>

      </div>




    </>
  );
};

export default BottomDrawer




const Close = (props) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 7L17 17M7 17L17 7"
      stroke="black"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
