


import React from 'react'
import "./style.css"

const OfferCard = ({data}) => {
  return (
    <div className='offer_card'>
          {data}
         <div className='prod_image'>
        <img
          src='https://s3-alpha-sig.figma.com/img/53d5/0356/6badedac0cd7b03e6e544f85bb6e1090?Expires=1725235200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=QtVRwGtZ~mn4jw4wLa-YCQTneHa5Rzm7gFMQqQyJUGtOHqhF~18tgTjA5gLzpiaJIA0MmL5E~x6oIUxk4a35~W6RYJ4boH7tbQ40n7HbAHSjER5khmkPEMnwmJpn9Rqr1ZgL3WRyHzsuNdXI4Xe~1NeKCWPjkZD1-huRlMeaoD3eVJMnO2F1eBXS1thAvuednYSV7tGduRLQ8dnKtaTIJRsr00IUvE2rgWO0BwK8Xkl~AyuBtR1XdmBhKFOFactZXj44lRQYS5Peb-iQydw4i4Csloq9MTff2tjyUz5PAKVd1VykRip9mBG~E9PR4C3WNAc~yGjRYOcqwe1EtjVaOg__'
          alt=''
        />
      </div>
      <div className='top_offer_card_data'>
        <h3>جبن كريم بوك ثوم</h3>
        <div className='d-flex align-items-center justify-content-between'>

        <p className='now_price'>11.95 ر.س</p>
        <p className='old_price'>15.95 ر.س</p>
        </div>
      </div>

      <div className='offer_card_counter'>
      <PlusIcon />
        1
      <TrashIcon />
      </div>
      
    </div>
  )
}

export default OfferCard



const PlusIcon = (props) => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14 1.75C10.7633 1.78927 7.67023 3.0925 5.38137 5.38137C3.0925 7.67023 1.78927 10.7633 1.75 14C1.78927 17.2367 3.0925 20.3298 5.38137 22.6186C7.67023 24.9075 10.7633 26.2107 14 26.25C17.2367 26.2107 20.3298 24.9075 22.6186 22.6186C24.9075 20.3298 26.2107 17.2367 26.25 14C26.2107 10.7633 24.9075 7.67023 22.6186 5.38137C20.3298 3.0925 17.2367 1.78927 14 1.75ZM21 14.875H14.875V21H13.125V14.875H7V13.125H13.125V7H14.875V13.125H21V14.875Z"
      fill="#F26E38"
    />
  </svg>
);



const TrashIcon = (props) => (
  <svg
    width={28}
    height={28}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.4808 7.58333L20.5463 23.4698C20.5115 24.0641 20.2509 24.6226 19.8177 25.031C19.3846 25.4394 18.8118 25.6668 18.2165 25.6667H9.78383C9.18853 25.6668 8.6157 25.4394 8.18259 25.031C7.74947 24.6226 7.48882 24.0641 7.454 23.4698L6.52183 7.58333H4.0835V6.41667C4.0835 6.26196 4.14495 6.11358 4.25435 6.00419C4.36375 5.89479 4.51212 5.83333 4.66683 5.83333H23.3335C23.4882 5.83333 23.6366 5.89479 23.746 6.00419C23.8554 6.11358 23.9168 6.26196 23.9168 6.41667V7.58333H21.4808ZM11.6668 2.91667H16.3335C16.4882 2.91667 16.6366 2.97813 16.746 3.08752C16.8554 3.19692 16.9168 3.34529 16.9168 3.5V4.66667H11.0835V3.5C11.0835 3.34529 11.145 3.19692 11.2544 3.08752C11.3637 2.97813 11.5121 2.91667 11.6668 2.91667ZM10.5002 10.5L11.0835 21H12.8335L12.3668 10.5H10.5002ZM15.7502 10.5L15.1668 21H16.9168L17.5002 10.5H15.7502Z"
      fill="#FB0101"
    />
  </svg>
);

