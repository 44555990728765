import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";

function useUserId() {
  const [userId, setUserId] = useState();
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      const queryParams = new URLSearchParams(window.location.search);
      const userIdFromQuery = localStorage?.getItem("user_id_uri");
      if (userIdFromQuery) {
        setUserId(userIdFromQuery);
      } else {
        setUserId(3);
      }

    }, 190);
  }, [location]);

  return {
    userId,
  };
}

export default useUserId;
