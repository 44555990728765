import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { BASEURL } from "../../API/BaseUrl";
import UseGeneral from "../../hooks/useGeneral";
import useUserId from "../../hooks/useUserId";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import CartPage from "./../../pages/CartPage/CartPage";
import { useDispatch, useSelector } from "react-redux";
import { setItemFilter } from "../../redux/cartSlice";

function calculateBestPrice(data) {
  let products = [];
  try {
    const choosedStores = JSON.parse(localStorage?.getItem("ksbStores"));
    products = data.map((product) => ({
      ...product,
      prices: JSON.parse(product.prices.replace(/'/g, '"'))
        ?.filter(
          (item) =>
            choosedStores?.filter(
              (ch_s_item) => ch_s_item?.market_name_ar == item[0]
            )?.length
        )
        ?.reduce((acc, [store, price]) => {
          acc[store] = parseFloat(price);
          return acc;
        }, {}),
    }));
  } catch (err) {
    console.log(err);
  }
  const stores = Array.from(
    new Set(products.flatMap((product) => Object.keys(product.prices)))
  );

  function calculateStoreTotal(store) {
    return products.reduce(
      (total, product) => total + (product.prices[store] || 0) * product.count,
      0
    );
  }

  const storeTotals = stores.map((store) => ({
    store,
    total: calculateStoreTotal(store),
  }));

  const [firstStore, secondStore] = storeTotals
    .sort((a, b) => a.total - b.total)
    .slice(0, 2);

  const bestPrice = products.reduce((total, product) => {
    const priceFromFirstStore = product.prices[firstStore.store] || Infinity;
    const priceFromSecondStore = product.prices[secondStore.store] || Infinity;
    const minPrice = Math.min(priceFromFirstStore, priceFromSecondStore);
    return total + minPrice * product.count;
  }, 0);

  const allPrices = products.reduce((acc, product) => {
    Object.entries(product.prices).forEach(([store, price]) => {
      if (!acc[store]) acc[store] = 0;
      acc[store] += price * product.count;
    });
    return acc;
  }, {});

  const maxPrice = Math.max(...Object.values(allPrices));
  const saving = maxPrice - bestPrice;

  const selectedProducts = products.map((product) => {
    const priceFromFirstStore = product.prices[firstStore.store] || Infinity;
    const priceFromSecondStore = product.prices[secondStore.store] || Infinity;
    return {
      ...product,
      selectedStore:
        priceFromFirstStore <= priceFromSecondStore
          ? firstStore.store
          : secondStore.store,
    };
  });

  const productsFromStores = stores.reduce((acc, store) => {
    const storeProducts = selectedProducts.filter(
      (product) => product.selectedStore === store
    );
    if (storeProducts.length > 0) {
      acc.push({
        store,
        products: storeProducts,
      });
    }
    return acc;
  }, []);

  return {
    bestPrice,
    saving: Math.max(0, saving),
    stores: [firstStore.store, secondStore.store],
    productsFromStores,
  };
}

const PrintingCart = ({ triggerElement, shwoContent }) => {
  const [loading, setLoading] = useState(false);
  const [cheapestStores, setCheapestStores] = useState([]);
  const [textResult, setTextResult] = useState("");
  const filterSouq = useSelector((state) => state?.cart?.filterItem);
  const { language } = UseGeneral();
  const { userId } = useUserId();

  const getCartData = async () => {
    setLoading(true);
    const dataSet = {
      user_id: userId,
      language_type: language === "ar" ? "arabic" : "english",
    };

    try {
      const response = await axios.post(
        `${BASEURL}products/select_user_favorite.php`,
        JSON.stringify(dataSet)
      );

      if (response.data.status === "success") {
        const products = response.data.message.filter(
          (item) => +item.count !== 0
        );

        const result = calculateBestPrice(products);

        const storesText = result.stores.join(" و ");
        setCheapestStores(result.productsFromStores);
        setTextResult(
          `بإمكانك توفير ${result.saving?.toFixed(
            2
          )} ريال عند اختيار البضائع الأرخص سعراً من أسواق ${storesText} ليصبح المجموع ${result.bestPrice?.toFixed(
            2
          )} ريال`
        );
      } else {
       // toast.error("Failed to fetch cart data.");
      }
    } catch (error) {
     // console.error("Error fetching cart data:", error);
      //toast.error("Error fetching cart data.");
    } finally {
      setLoading(false);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const printCart = async () => {
    await handlePrint();
    // dispatch(setItemFilter(""));
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (userId) getCartData();
  }, [userId]);
  const componentRef = useRef();

  return (
    <>
      {triggerElement && <div>{triggerElement}</div>}

      {filterSouq && filterSouq?.length ? (
        <div className="rowDiv d-flex gap-1 align-items-center justify-content-end my-2 mx-4">
          <button
            className="btn btn-danger"
            style={{
              // margin:"10px 15px 10px auto",
              // marginLeft: "auto",
              width: "fit-content",
            }}
            onClick={() => dispatch(setItemFilter(""))}
          >
            {language == "ar" ? "الكل" : "clear"}
          </button>
          <button
            className="btn btn-primary"
            style={{
              // margin:"10px 15px 10px auto",
              // marginLeft: "auto",
              width: "fit-content",
            }}
            onClick={() => printCart()}
          >
            {language == "ar" ? "طباعة" : "print"}
          </button>
        </div>
      ) : (
        <div className="rowDiv d-flex gap-1 align-items-center justify-content-end my-2 mx-4">
          {" "}
          <button
            className="btn btn-primary"
            style={{
              // margin:"10px 15px 10px auto",
              // marginLeft: "auto",
              width: "fit-content",
            }}
            onClick={() => printCart()}
          >
            {language == "ar" ? "طباعة" : "print"}
          </button>{" "}
        </div>
      )}

      <div className="d-none">
        <div style={{ marginBottom: "90px", padding: "10px" }}>
          <p style={{ padding: "10px" }}>
            {textResult}
            <ReactToPrint
              trigger={() => {
                return (
                  <span style={{ cursor: "pointer" }}>
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="2.8em"
                      height="2.8em"
                      viewBox="0 0 50 50"
                    >
                      <g
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                      >
                        <path
                          stroke="#306cfe"
                          d="M35.604 33.333h6.063a2.083 2.083 0 0 0 2.083-2.083V14.583a2.083 2.083 0 0 0-2.083-2.083H8.333a2.083 2.083 0 0 0-2.083 2.083V31.25a2.083 2.083 0 0 0 2.083 2.083h6.063M35.417 6.25H14.583v6.25h20.834z"
                        ></path>
                        <path
                          stroke="#344054"
                          d="M37.5 43.75h-25l4.167-22.917h16.666z"
                        ></path>
                      </g>
                    </svg>
                  </span>
                );
              }}
              onPrintError={() => dispatch(setItemFilter(null))}
              onAfterPrint={() => dispatch(setItemFilter(null))}
              // removeAfterPrint={true}
            />
          </p>
          <div className="printable-content" ref={componentRef}>
            <CartPage handlePrintBe={handlePrint} filterSouq={filterSouq} />
          </div>
        </div>
      </div>
    </>
  );
};

export default PrintingCart;
