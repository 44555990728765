import React from "react";
import "./style.css";
import {FlatRightArrow} from "../../assets/svgIcons/svgIcons";
import img from "../../assets/images/greens.png";
import {Swiper, SwiperSlide} from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import { useNavigate } from "react-router-dom";



const CategoryCard = ({ cat , selectedCat , setSelectedCat}) => {

  const navigate = useNavigate()

  return (
    <div className={`category_card ${+selectedCat?.id == +cat?.id ? "open" : ""}`} onClick={()=>{
      if(selectedCat?.id == cat?.id){
        setSelectedCat(null)
        return
      }
      setSelectedCat(cat)
    
    }}>
      <div className='layout'>
        <div className='title'>
          <div className='icon'>{FlatRightArrow}</div>
          <div className='category_title'>{cat.catName}</div>
        </div>

        <div className='filter'>
          <>
            <Swiper
              // slidesPerView='auto'
              // direction="rtl"
              spaceBetween={10}
              // slidesPerGroupSkip={4}
              slidesPerView={"auto"}
              className='mySwiper w-100 '
            >
              {cat?.subCats?.map((subcat, index) => (
                <SwiperSlide  key={index} style={{width:'fit-content'}} >
                  <div
                    key={index}
                    className={` h-100 bg-light swiper_slide `}
                    onClick={()=>{
                      navigate(`/subcatProducts/${subcat.id}`)
                    }}
                  >
                    <div className="slide_img">
                      <img src={subcat.img} alt="" />
                    </div>
                    <div className="slide_text">
                      {subcat.name}
                    </div>
                  </div>
                </SwiperSlide>
              ))}

            </Swiper>
          </>
        </div>
      </div>

    </div>
  );
};

export default CategoryCard;
