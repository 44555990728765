import React, { useState } from 'react';
import './style.css'; 

const RadioButton = ({ id, value, checked, onChange, children , labelStyle }) => {
  return (
    <label className="custom-radio" htmlFor={id}>
      <div style={
        labelStyle? labelStyle : null
      }>
        {children}
      </div>
      <div>
        <input 
          id={id}
          type="radio" 
          value={value} 
          checked={checked} 
          onChange={onChange} 
          className="custom-radio-input" 
        />
        <span className="custom-radio-dot">
          <span className='small_dot'></span>
        </span>

      </div>
    </label>
  );
};

export default RadioButton;
