


import React from 'react';
import "./style.css";
import { useNavigate } from 'react-router-dom';
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import "./style.css"

import offer1 from "../../../assets/images/offer1.png"
import offer2 from "../../../assets/images/offer-2.png"

const MainCategories = () => {


  const navigate = useNavigate()

  return (
    <div  className='main_categories'>
        <>
            <Swiper
              // direction="rtl"
              spaceBetween={10}
              // slidesPerGroupSkip={4}
              slidesPerView={"auto"}
              className='mySwiper w-100 '
            >
              {[offer1 , offer2 , offer1 , offer2, offer1 , offer2].map((offer, index) => (
                <SwiperSlide  key={index} style={{width:'fit-content'}} >
                    <div className='mainCatImage'>
                        <img src={offer} alt="" />
                    </div>
                </SwiperSlide>
              ))}

            </Swiper>
          </>
    </div>
  )
}

export default MainCategories
