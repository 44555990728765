import React from 'react'
import "./style.css"

const Switch = ({onChange, checked, id }) => {
  return (
      <div class="toggle-switch">
        <input
          checked={checked}
          class="toggle-input"
          id={id}
          type="checkbox"
          onChange={onChange}
        />
        <label class="toggle-label" for={id}></label>
      </div>
  )
}

export default Switch
