import React, {useEffect, useRef, useState} from "react";
import "./style.css";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {ChevronLeft, upLoadIcon} from "../../assets/svgIcons/svgIcons";
import SearchInput from "./../../components/SearchInput/SearchInput";
import {fakeCtegories, fakeProducts} from "../../data";
import NewProductCard from "../../components/NewProductCard/NewProductCard";
import {BASEURL} from "../../API/BaseUrl";
import axios from "axios";
import toast from "react-hot-toast";
import UseGeneral from "../../hooks/useGeneral";
import logo from "../../assets/kssap.png";
import ProdSearchCard from "../../components/prodSearchCard/ProdSearchCard";
import BottomDrawer from "../../components/BottomDrawer/BottomDrawer";

// images

const Header = ({onOpenDrawer}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchValue, setSearchValue] = useState("");
  const [focusSearch, setFocusSearch] = useState(false);

  const [categoriesData, setCategoriesData] = useState([]);
  const [originalCategories, setOriginalCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [prodLoading, setProdLoading] = useState(false);

  const [productsData, setProductsData] = useState([]);
  const [originalProducts, setOriginalProducts] = useState([]);
  const [isFadeOut, setIsFadeOut] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const inputRef = useRef(null);
  const {language} = UseGeneral();

  useEffect(() => {
    // Get a parameter value
    if (!searchParams.has("user_id")) {
      searchParams.set("user_id", "1");
      setSearchParams(searchParams);
    }
  }, []);

  const selectCategories = async () => {
    setProdLoading(true);
    await axios
      .get(`${BASEURL}products/select_categories.php`)
      .then((res) => {
        if (res.data.status == "success") {
          setCategoriesData(res?.data?.message);
          setOriginalCategories(res?.data?.message);
          console.log(res.data.message);
        } else {
          toast.error("هناك مشكلة ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setProdLoading(false);
      });
  };

  const selectSubCategories = async () => {
    setLoading(true);
    const dataSet = {
      language_type: language == "ar" ? "arabic" : "english",
    };

    await axios
      .post(
        `${BASEURL}products/select_search_products.php`,
        JSON.stringify(dataSet)
      )
      .then((res) => {
        if (res.data.status == "success") {
          setProductsData(res?.data?.message?.products);
          setOriginalProducts(res?.data?.message?.products);
          console.log(res.data.message);
        } else {
          toast.error("هناك مشكلة ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    selectCategories();
    selectSubCategories();
  }, [language]);

  const categoriesFiltrations = () => {
    if (originalCategories && originalCategories.length >= 1) {
      if (searchValue.length > 0) {
        const newData = originalCategories.filter((cat) => {
          if (
            searchValue.length >= 1 &&
            !cat[language == "ar" ? "name_ar" : "name_en"]
              ?.toLowerCase()
              ?.includes(searchValue?.toLocaleLowerCase())
          ) {
            return false;
          }
          return true;
        });
        setCategoriesData(newData);
      } else {
        setCategoriesData(originalCategories);
      }
    }
  };

  const productsFiltrations = () => {
    if (originalProducts && originalProducts.length >= 1) {
      if (searchValue.length > 0) {
        const newData = originalProducts.filter((prod) => {
          if (
            searchValue.length >= 1 &&
            !prod?.name
              ?.toLowerCase()
              ?.includes(searchValue?.toLocaleLowerCase())
          ) {
            return false;
          }
          return true;
        });
        setProductsData(newData);
      } else {
        setProductsData(originalProducts);
      }
    }
  };

  useEffect(() => {
    // categories filtration
    categoriesFiltrations();
    // products filtrations
    productsFiltrations();
  }, [searchValue]);

  useEffect(() => {
    setFocusSearch(false);
    // inputRef.current.blur()
  }, [location]);

  return (
    <div className='header_container'>
      <div className='top_header'>
        <div
          className={`header_icon ${language == "ar" ? "" : "english"}`}
          onClick={() => {
            navigate(-1);
            setSearchValue("");
            setFocusSearch(false);
          }}
        >
          {ChevronLeft}
        </div>
        <div className='page_name'>
          <img src={logo} alt='' style={{width:"100px"}} />
        </div>
        <div className='header_icon mt-4s' onClick={() => onOpenDrawer(true)}>
          {upLoadIcon}
        </div>
      </div>
      <div className='searchHeader mt-3'>
        {!["settings", "productDetails", "cart"].find((item) =>
          location.pathname.includes(item)
        ) && (
          <>
            <SearchInput
              reference={inputRef}
              name='search'
              value={searchValue}
              onFocus={() => setFocusSearch(true)}
              isFocust={focusSearch}
              onCloseClick={() => {
                setSearchValue("");
                setIsFadeOut(true);
                setTimeout(() => {
                  setIsFadeOut(false);
                  setFocusSearch(false);
                }, 500);
              }}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder={
                language == "ar" ? "ابحث عن المنتجات" : "Search for a product "
              }
            />
            <p
              style={{cursor: "pointer", margin: "0"}}
              onClick={() => navigate("/ProductSaved")}
            >
              {" "}
              <img
                width={40}
                src='https://res.cloudinary.com/duovxefh6/image/upload/v1722090941/png-clipart-computer-icons-paper-clip-comparison-of-commercial-battery-types-angle-text_s46al8-removebg-preview_yganxv.png'
                alt=''
              />
            </p>
          </>
        )}
      </div>
      {focusSearch && (
        <div className={`search_popup ${isFadeOut && "fade_out"}`}>
          {/* {categoriesData &&
              categoriesData.length >= 1 &&
              Array.isArray(categoriesData) ?
              <>
                <h5>{language == "ar" ? "التصنيفات" : "Categories"}</h5>
                <div className='categories_items'>

                  {categoriesData?.map((item, index) => {
                    return (
                      <div
                        className={`search_category_card`}
                        onClick={() => {
                          setSearchValue("")
                          navigate(`/cats_prods/${item.category_id}`)
                        }}
                      >
                        <div className='search_cat_card_image'>
                          <img src={item.image} alt='' />
                        </div>
                        <div className='search_cat_card_text'>{item[language == "ar" ? "name_ar" : "name_en"]}</div>
                      </div>
                    );
                  })}

                </div>
              </>
              :

              <div className="m-2 text-center fs-5">
                {
                  language == "ar" ? "لا يوجد بيانات مطابقة" : "No Results"
                }
              </div>

            } */}
          <div className='my-3'></div>
          {prodLoading ? (
            <div className='d-flex align-items-center justify-content-center w-100'>
              <div
                class='spinner-border text-primary text-center'
                role='status'
              >
                <span class='visually-hidden text-center'>Loading...</span>
              </div>
            </div>
          ) :
          

          
          productsData &&
            productsData.length >= 1 &&
            Array.isArray(productsData) ? (
            <>
              {/* <h5>المنتجات</h5> */}

              <div className='search_products_items'>
                {productsData?.map((item, index) => {
                  return (
                    <div className='search_prod_card'>
                      {/* <NewProductCard data={item} /> */}
                      <ProdSearchCard
                        setFocusSearch={setFocusSearch}
                        data={item}
                      />
                    </div>
                  );
                })}
              </div>
            </>
          ) : null
          
          
          }
        </div>
      )}
    </div>
  );
};

export default Header;
