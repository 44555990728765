import styles from "./PrivacyPolicy.module.css";

export default function PrivacyPolicy() {
  return (
    <div className={styles.privacyContainer}>
      <p>
        HERE Global B.V., including its affiliates (“HERE”), is committed to
        respect your privacy and to comply with applicable data protection and
        privacy laws. This privacy policy (“Policy”) describes how we collect
        and use personal data where HERE is the data controller or where we
        refer to the applicability of this Policy. “Personal data” means
        information relating to you or another identifiable individual.
      </p>
      <p>
        We will give you additional privacy information that is specific to a
        product or service in Supplements to this Policy and other notices you
        may see while using our products or services. If there is a difference
        between such notices and this Policy, the notices should be considered
        first. For additional information, check out HERE's{" "}
        <a href="Privacy Charter">Privacy Charter</a>.
      </p>
      <p>
        Software on your device may access your information. Our products or
        services may contain links to, or may be embedded within, other
        companies’ websites and services that have privacy policies of their
        own. Where our products or services are embedded to products and
        services of our customers, we require our customers to provide necessary
        transparency to you. This might include linking to this Policy and the
        relevant Supplements, or providing the transparency in integrated and
        embedded notices which identify us as the service provider or
        controller.
      </p>
      <h5>
        If you do not agree with this Policy, do not use our products and
        services or provide HERE with your personal data.
      </h5>
      <h5>What information do we collect?</h5>
      <p>
        We collect your personal data and other information when you make a
        purchase, use or register into our products and services (including
        navigation.com stores, which are branded by Original Equipment
        Manufacturers (“OEMs”) and operated by us), take part in campaigns or
        research or otherwise interact with us. This includes following
        categories:
      </p>
    </div>
  );
}
