import React from "react";


import {Swiper, SwiperSlide} from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

import "./style.css"

const SubSubCatFilter = ({subSubCatsData , selectedSubSubCat, setSelectedSubSubCat}) => {
  return (
    <div className='subSubCats_container'>
      <div className=''>
        <>
          <Swiper
            // slidesPerView='auto'
            // direction="rtl"
            spaceBetween={20}
            // slidesPerGroupSkip={4}
            slidesPerView={"auto"}
            className='mySwiper w-100 '
          >
             <SwiperSlide style={{width: "fit-content"}}>
                <div
                  className={` h-100 subSubCatSlide  ${selectedSubSubCat ==  "all" ? "active":""}`}
                  onClick={() => {""}}
                >
                  <div className='slide_text' onClick={()=>{
                    setSelectedSubSubCat("all")
                  }}>
                    الكل
                  </div>
                </div>
              </SwiperSlide>
            {subSubCatsData?.map((subSubCat, index) => (
              <SwiperSlide key={index} style={{width: "fit-content"}}>
                <div
                  key={index}
                  className={` h-100 subSubCatSlide  ${selectedSubSubCat.id ==  subSubCat.id ? "active":""}`}
                  onClick={() => {
                    setSelectedSubSubCat(subSubCat)
                  }}
                >
                  <div className='slide_text'>
                    {subSubCat.name}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </>
      </div>
    </div>
  );
};

export default SubSubCatFilter;
