



import React from 'react'
import "./style.css"
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import "./style.css"

import offer1 from "../../../assets/images/offer1.png"
import offer2 from "../../../assets/images/offer-2.png"
import TopOfferCard from './TopOfferCard/TopOfferCard';

const TopOffers = () => {
  return (
    <div className='top_offers'>
      <>
           <Swiper
              // direction="rtl"
              spaceBetween={13.5}
              // slidesPerGroupSkip={4}
              slidesPerView={"auto"}
              className='mySwiper w-100 '
            >
               {[offer1 , offer2 , offer1 , offer2, offer1 , offer2].map((offer, index) => (
                <SwiperSlide  key={index} style={{width:'fit-content'}} >
                    <TopOfferCard />
                </SwiperSlide>
              ))}

            </Swiper>
          </>
    </div>
  )
}

export default TopOffers
