
import carrefour from "./assets/images/carrefour.png"
import lolo from "./assets/images/lolo.png"
import otheim from "./assets/images/otheim.jpg"
import apple from "./assets/images/apple.jpg"
import banana from "./assets/images/banana.jpg"
import rows from "./assets/images/rows.jpg"
import ananas from "./assets/images/ananas.jpg"
import greens from "./assets/images/greens.png"
import orange from "./assets/images/orange.png"
import berry from "./assets/images/berry.png"
import panda from "./assets/images/panda.png"



import coffee from "./assets/images/coffee.png";
import cans from "./assets/images/pop-cans.png";
import vigitables from "./assets/images/vigitables.png";
import breads from "./assets/images/breads.png";

import prod from "./assets/images/prod_1.png";





export const categories = [
  {
    id:1,
    catName:"الخضراوات",
    img: greens,
    subCats:[
      {
        id:1,
        name:'الفواكه الطازجة',
        img:greens
      },
      {
        id:2,
        name:'الخضراوات الطازجة',
        img:greens
      },
      {
        id:3,
        name:'الخضراوات المجمدة',
        img:greens
      },
      {
        id:4,
        name:' الأعشاب الجافة والتوابل',
        img:greens
      },
      {
        id:5,
        name:' الأعشاب الجافة والتوابل',
        img:greens
      },
    ]
  },
  {
    id:2,
    catName:"الفواكه",
    img: greens,
    subCats:[
      {
        id:6,
        name:'تفاح',
        img:apple
      },
      {
        id:7,
        name:'موز',
        img:banana
      },
      {
        id:8,
        name:'برتقال',
        img:orange
      },
      {
        id:9,
        name:' عنب',
        img:rows
      },
      {
        id:10,
        name:' توت',
        img: berry,
      },
    ]
  },
  {
    id:3,
    catName:"الحلويات",
    img: greens,
    subCats:[
      {
        id:11,
        name:'شوكلاتة',
        img:apple
      },
      {
        id:12,
        name:'حلوى',
        img:banana
      },
      {
        id:13,
        name:'آيس كريم',
        img:orange
      },
      {
        id:14,
        name:' كعك',
        img:rows
      },
      {
        id:15,
        name:' توت',
        img: berry,
      },
    ]
  },
  {
    id:4,
    catName:"الألبان",
    img: greens,
    subCats:[
      {
        id:16,
        name:'حليب',
        img:apple
      },
      {
        id:17,
        name:'جبن',
        img:banana
      },
      {
        id:18,
        name:'زبادي',
        img:orange
      },
      {
        id:19,
        name:' زبدة',
        img:rows
      },
      
    ]
  },
]

  export  const subSubCats = [
    {
      id:1,
      name:'البرتقال 1'
    },
    {
      id:2,
      name:'البرتقال 2'
    },
    {
      id:3,
      name:'البرتقال 3'
    },
  ]

  export const products=[
      {
        id:1,
        name:'تفاح',
        subSubCat_id:1,
        img:apple,
        prices:[
          {
            from:'carrefour',
            img:carrefour,
            price:6
          },
          {
            from:'otheim',
            img:otheim,
            price:7
          },
          {
            from:'lolo',
            img:lolo,
            price:6
          },
          {
            from:'panda',
            img:panda,
            price:8
          },
        ]
      },
      {
        id:2,
        name:'موز',
        img:banana,
        subSubCat_id:1,
        prices:[
          {
            from:'carrefour',
            img:carrefour,
            price:6
          },
          {
            from:'otheim',
            img:otheim,
            price:7
          },
          {
            from:'lolo',
            img:lolo,
            price:6
          },
          {
            from:'panda',
            img:panda,
            price:8
          },
        ]
      },
      {
        id:3,
        name:'عنب',
        img:rows,
        subSubCat_id:2,
        prices:[
          {
            from:'carrefour',
            img:carrefour,
            price:6
          },
          {
            from:'otheim',
            img:otheim,
            price:7
          },
          {
            from:'lolo',
            img:lolo,
            price:6
          },
          {
            from:'panda',
            img:panda,
            price:8
          },
        ]
      },
      {
        id:4,
        name:'أناناس',
        img:ananas,
        subSubCat_id:3,
        prices:[
          {
            from:'carrefour',
            img:carrefour,
            price:6
          },
          {
            from:'otheim',
            img:otheim,
            price:7
          },
          {
            from:'lolo',
            img:lolo,
            price:6
          },
          {
            from:'panda',
            img:panda,
            price:8
          },
        ]
      },
    ]







    
export const fakeCtegories = [
  {
    id: 1,
    image: coffee,
    text: "قهوة",
  },
  {
    id: 2,
    image: breads,
    text: "الخضراوات والفواكه",
  },
  {
    id: 3,
    image: vigitables,
    text: "الخضراوات والفواكه",
  },
  {
    id: 4,
    image: cans,
    text: " المشروبات",
  },
  {
    id: 5,
    image: coffee,
    text: "قهوة",
  },
  {
    id: 6,
    image: breads,
    text: "الخضراوات والفواكه",
  },
  {
    id: 7,
    image: vigitables,
    text: "الخضراوات والفواكه",
  },
  {
    id: 8,
    image: cans,
    text: " المشروبات",
  },
];

export const fakeProducts = [
  {
    id:1,
    name:'تسالي شيبس  فلفل حار 155جرام',
    price:'5.75',
    image:prod
  },
]
