import React, { useEffect, useRef, useState } from "react";

import useSelection from "antd/es/table/hooks/useSelection";
import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { BASEURL } from "../../API/BaseUrl";
import UseGeneral from "../../hooks/useGeneral";
import useUserId from "../../hooks/useUserId";
import { getRender } from "../../redux/rerednerSlice";
import { exitModal } from "./../../assets/svgIcons/svgIcons";
import "./style.css";
import ReactToPrint from "react-to-print";

const CartPage = ({ handlePrintBe }) => {
  const [cartData, setCartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allMarkets, setAllMarkets] = useState([]);
  const { language } = UseGeneral();
  const [searchParam, setSearchParam] = useSearchParams();
  const { userId } = useUserId();

  const getCartData = async () => {
    setLoading(true);
    const dataSet = {
      user_id: userId,
      language_type: language == "ar" ? "arabic" : "english",
    };
    await axios
      .post(
        `${BASEURL}products/select_user_favorite.php`,
        JSON.stringify(dataSet)
      )
      .then((res) => {
        if (res.data.status == "success") {
          const storedStores = JSON.parse(localStorage.getItem("ksbStores"));
          let newData = res?.data?.message
            .map((prod) => {
              const prodDataPrices = JSON.parse(prod.prices?.replace(/'/g, '"'))
                .filter((price) => {
                  const isExist = storedStores?.find(
                    (store) =>
                      store[
                        language == "ar" ? "market_name_ar" : "market_name_en"
                      ] == price[0]
                  );
                  if (isExist) {
                    return true;
                  }
                })
                .map((item) => {
                  return {
                    name: item[0],
                    value: item[1],
                  };
                })
                .map((item) => {
                  let itemData = storedStores.find(
                    (store) =>
                      store[
                        language == "ar" ? "market_name_ar" : "market_name_en"
                      ] == item.name
                  );
                  return {
                    ...itemData,
                    price: item.value,
                  };
                });

              return {
                ...prod,
                prices: prodDataPrices,
              };
            })
            .filter((item) => +item.count !== 0);
          if (filterSouq && filterSouq?.length) {
            newData = res?.data?.message
              .map((prod) => {
                const prodDataPrices = JSON.parse(
                  prod.prices?.replace(/'/g, '"')
                )
                  .filter((price) => {
                    const isExist = storedStores
                      ?.filter(
                        (item) =>
                          item[
                            language == "ar"
                              ? "market_name_ar"
                              : "market_name_en"
                          ] == filterSouq
                      )
                      ?.find(
                        (store) =>
                          store[
                            language == "ar"
                              ? "market_name_ar"
                              : "market_name_en"
                          ] == price[0]
                      );
                    if (isExist) {
                      return true;
                    }
                  })
                  .map((item) => {
                    return {
                      name: item[0],
                      value: item[1],
                    };
                  })
                  .map((item) => {
                    let itemData = storedStores.find(
                      (store) =>
                        store[
                          language == "ar" ? "market_name_ar" : "market_name_en"
                        ] == item.name
                    );
                    return {
                      ...itemData,
                      price: item.value,
                    };
                  });

                return {
                  ...prod,
                  prices: prodDataPrices,
                };
              })
              .filter((item) => +item.count !== 0);
          }
          setCartData(newData);
          // const parsedData =
          const allMarketsData = newData
            ?.map((item) => ({
              ...item,
              prices: item.prices.map((p_item) => ({
                ...p_item,
                count: item.count_in_favorite,
              })),
            }))
            .map((data) => data.prices)
            .flat(1);

          const sums = {};
          console.log(allMarketsData)
          allMarketsData.forEach((item) => {
            const name =
              item[language == "ar" ? "market_name_ar" : "market_name_en"]; // Assuming you want to sum based on Arabic names
            const price = parseFloat(item.price);
            if (sums[name]) {
              sums[name] += parseInt(item?.count) * price;
            } else {
              sums[name] = parseInt(item?.count) * price;
            }
          });

          const result = Object.keys(sums).map((name) => ({
            market_name_ar: name,
            price: sums[name].toFixed(2),
            // Ensuring the price is formatted as a string with 2 decimal places
          }));
          if (filterSouq && filterSouq?.length) {
            setAllMarkets(
              result?.filter((item) =>
                 item?.market_name_ar == filterSouq
              )
            );
            console.log(result);
          } else {
            setAllMarkets(result);
          }

          // const storeSums = {};
          // allMarketsData.forEach(([store, value]) => {
          //   console.log(store)
          //   const numValue = parseFloat(value);
          //   if (storeSums[store]) {
          //     storeSums[store] += +numValue;
          //   } else {
          //     storeSums[store] = +numValue;
          //   }
          // });

          // const resultArray = Object.entries(storeSums);

          // setAllMarkets(resultArray)

          // console.log(allMarketsData)
        } else {
         // toast.error("هناك مشكلة ما");
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (userId) getCartData();
  }, [userId]);

  const dispatch = useDispatch();
  const cartProdsData = useSelection((state) => state.cart.cart);

  const handleChangeCount = (action, id) => {
    const cartData = JSON.parse(localStorage.getItem("kspCart")) || [];

    if (action == "remove") {
      const newData = cartData.filter((item) => item.id != id);
      localStorage?.setItem("kspCart", JSON.stringify(newData));
      getCartData();
      return;
    }
    if (cartData) {
      const newData = cartData.map((item) => {
        if (item.id == id) {
          return {
            ...item,
            count: action == "add" ? +item.count + 1 : +item.count - 1,
          };
        }
        return item;
      });
      localStorage.setItem("kspCart", JSON.stringify(newData));
      getCartData();
    }
  };

  const changeCountFromCart = async (action, prod, count) => {
    const dataSet = {
      product_code: prod.product_code,
      user_id: userId,
      count:
        action == "add" ? +count + 1 : action == "minus" ? +count - 1 : count,
      // every time send the new count if up or low
      // if you send zero the fav will be removed auto
    };

    await axios
      .post(`${BASEURL}products/add_edit_to_fav.php`, JSON.stringify(dataSet))
      .then((res) => {
        if (res.data.status == "success") {
          if (action == "add") {
            toast.success(
              language == "ar" ? "تمت الإضافة بنجاح" : "Added successfully"
            );
          } else {
            toast.success(
              language == "ar" ? "تمت الازالة بنجاح" : "Deleted successfully"
            );
          }

          getCartData();
          dispatch(getRender());
        } else {
          //toast.error(
            //language == "ar" ? "هناك مشكلة ما" : "There is a problem"
          //);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setLoading(false);
      });
  };
  const componentRef = useRef();
  const filterSouq = useSelector((state) => state?.cart?.filterItem);

  useEffect(() => {
    // alert(filterSouq);
    getCartData();
  }, [filterSouq]);
  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center m-5">
        <div class="spinner-border text-primary text-center" role="status">
          <span class="visually-hidden text-center">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="cart_page" ref={componentRef}>
        <div className="cart_content">
          {cartData && cartData.length >= 1 && Array.isArray(cartData) ? (
            <>
              <div className="cart_prod_rows">
                <table
                  className={`figma_cart_table ${
                    language == "ar" ? "" : "eng"
                  }`}
                >
                  <thead className="figma_table_header ">
                    <tr>
                      <th className="buttons_header">
                        <span className="buttons_header_text">
                          {language == "ar" ? "الكمية" : "Quantity"}
                        </span>
                      </th>
                      <th className="marketName name_header corner_1">
                        {" "}
                        {language == "ar" ? "اسم المنتج" : "Product Name"}
                      </th>
                      {allMarkets.map((item, index) => {
                        return (
                          <th className="marketName">{item.market_name_ar}</th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      <>
                        {cartData?.map((proItem, index) => {
                          return (
                            <tr key={index}>
                              <th className="figma_count_buttons">
                                <div
                                  className="count_btn"
                                  onClick={() =>
                                    changeCountFromCart(
                                      "add",
                                      proItem,
                                      proItem.count
                                    )
                                  }
                                >
                                  <PlusIcon />
                                </div>
                                <div className="prod_count">
                                  {proItem?.count}
                                </div>
                                <div
                                  className={`count_btn`}
                                  onClick={() =>
                                    +proItem.count >= 1
                                      ? changeCountFromCart(
                                          "minus",
                                          proItem,
                                          proItem.count
                                        )
                                      : null
                                  }
                                >
                                  {proItem.count > 1 ? (
                                    <MinusIcon />
                                  ) : (
                                    <TrashIcon />
                                  )}
                                </div>
                              </th>
                              <th className="figma_cart_prod_image">
                                <img src={proItem.caption} alt="" />
                                <p>{proItem.name}</p>
                              </th>
                              {proItem?.prices &&
                              proItem?.prices.length >= 1 &&
                              Array.isArray(proItem?.prices)
                                ? proItem?.prices?.map((prodPrice, index) => {
                                    return (
                                      <th
                                        key={index}
                                        className={`figma_cart_prod_price ${
                                          +Math.min(
                                            ...proItem.prices.map(
                                              (price) => price.price
                                            )
                                          ) == prodPrice.price
                                            ? "active"
                                            : ""
                                        }`}
                                      >
                                        {(
                                          prodPrice.price * proItem.count
                                        ).toFixed(2)}
                                      </th>
                                    );
                                  })
                                : ""}
                            </tr>
                          );
                        })}

                        <tr className="figma_table_footer">
                          <th className="buttons_header"></th>
                          <th className="marketName name_header figma_cart_prod_price corner_2">
                            {language == "ar" ? "المجموع" : "The Total"}
                          </th>
                          {allMarkets.map((item, index) => {
                            return (
                              <th
                                className={`figma_cart_prod_price ${
                                  Math.min(
                                    ...allMarkets.map((item) => item.price)
                                  ) == item.price
                                    ? "active"
                                    : ""
                                }`}
                                key={index}
                              >
                                {item?.price}
                              </th>
                            );
                          })}
                        </tr>
                      </>
                    }

                    {/* {allMarkets
                      .map((item, index) => {
                        return (
                          <th
                            className={`marketPrice ${Math.min(...allMarkets.map(item => item.price)) == item.price ? "active" : ""

                              }`}
                            key={index}
                          >
                            {item?.price}
                          </th>
                        );
                      })} */}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <div className="text-center my-4 fs-5 text-secondary">
              {language == "ar"
                ? "لا يوجد منتجات في السلة"
                : "No products in favorits"}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CartPage;

const PlusIcon = (props) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 1C6.15046 1.02244 4.38299 1.76714 3.07507 3.07507C1.76714 4.38299 1.02244 6.15046 1 8C1.02244 9.84954 1.76714 11.617 3.07507 12.9249C4.38299 14.2329 6.15046 14.9776 8 15C9.84954 14.9776 11.617 14.2329 12.9249 12.9249C14.2329 11.617 14.9776 9.84954 15 8C14.9776 6.15046 14.2329 4.38299 12.9249 3.07507C11.617 1.76714 9.84954 1.02244 8 1ZM12 8.5H8.5V12H7.5V8.5H4V7.5H7.5V4H8.5V7.5H12V8.5Z"
        fill="#F26E38"
      />
    </svg>
  );
};

const TrashIcon = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2748 4.33332L11.7408 13.4113C11.7209 13.7509 11.572 14.07 11.3245 14.3034C11.077 14.5368 10.7497 14.6667 10.4095 14.6667H5.59083C5.25066 14.6667 4.92333 14.5368 4.67583 14.3034C4.42834 14.07 4.2794 13.7509 4.2595 13.4113L3.72683 4.33332H2.3335V3.66666C2.3335 3.57825 2.36862 3.49347 2.43113 3.43095C2.49364 3.36844 2.57842 3.33332 2.66683 3.33332H13.3335C13.4219 3.33332 13.5067 3.36844 13.5692 3.43095C13.6317 3.49347 13.6668 3.57825 13.6668 3.66666V4.33332H12.2748ZM6.66683 1.66666H9.3335C9.4219 1.66666 9.50669 1.70178 9.5692 1.76429C9.63171 1.8268 9.66683 1.91158 9.66683 1.99999V2.66666H6.3335V1.99999C6.3335 1.91158 6.36861 1.8268 6.43113 1.76429C6.49364 1.70178 6.57842 1.66666 6.66683 1.66666ZM6.00016 5.99999L6.3335 12H7.3335L7.06683 5.99999H6.00016ZM9.00016 5.99999L8.66683 12H9.66683L10.0002 5.99999H9.00016Z"
      fill="#FB0101"
    />
  </svg>
);

const MinusIcon = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 15C6.14348 15 4.36301 14.2625 3.05025 12.9497C1.7375 11.637 1 9.85651 1 8C1 6.14348 1.7375 4.36301 3.05025 3.05025C4.36301 1.7375 6.14348 1 8 1C9.85651 1 11.637 1.7375 12.9497 3.05025C14.2625 4.36301 15 6.14348 15 8C15 9.85651 14.2625 11.637 12.9497 12.9497C11.637 14.2625 9.85651 15 8 15ZM11.5 7.3H4.5V8.7H11.5V7.3Z"
      fill="#F26E38"
    />
  </svg>
);
